import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CommanSerivceService {
  jsonData:any;
  jsonDataSub = new BehaviorSubject(null);

  constructor()  {
    this.jsonDataSub.subscribe((value) => {
        this.jsonData = value
    });
  }

  triggerSubChange(data) {
    this.jsonDataSub.next(data);
  }

  /* Mark touch to all the form fields */
   validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
  }

  /*  White space validator  */
  noWhitespaceValidator(control: FormControl){
      let isWhitespace = !(control.value === null || control.value === '') && (control.value).trim().length === 0;
      let isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
  }

}
