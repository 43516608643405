export const environment = {
  production: true, 
  api_url: 'https://orgs.ezycollect.io/EzyOrg/', 
  header: 'QmFzaWMgWlhwNVgyRmlibDloY0drNk16SmhabEpoYXpGeVZuQXg=',
  webhook: 'https://hooks.zapier.com/hooks/catch/8947312/o09c86a/',
  firebase_Integrate: {
    apiKey: "AIzaSyAwelMWiqjNwvyg-ansp3pg_B_m3Y3LwWY",
    authDomain: "ezycollect-167.firebaseapp.com",
    projectId: "ezycollect-167",
    storageBucket: "syn-ezycollect-167-bucket-dev",
    messagingSenderId: "549036303417",
    appId: "1:549036303417:web:74d89303e6eb0988d359e8",
    measurementId: "G-M8K76XGKWB"
  },
  ACCESS_KEY: '167_Ezy_Collect'

};