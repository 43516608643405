import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';


@Component({
  selector: 'app-outstanding',
  templateUrl: './outstanding.component.html',
  styleUrls: ['./outstanding.component.scss']
})

export class OutstandingComponent implements OnInit {

  orgId: any;
  dataunavailable: boolean = false;
  jsonData: any;
  daysToCollect: any = [];
  rollingSixMonth: any = [];
  totalMonth: any = [];
  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  rollingAvgFirstDay: any = "0";
  rollingAvgLastDay: any = "0";
  rollingAvgResult: any = "0";
  public href: string = "";
  devDomain: string = "https://eofyreport.ezycollect.com.au"; 
  devUrl: string = "";

  constructor( private activeRoute: ActivatedRoute,
    private router: Router,
    private json_s: CommanSerivceService) {
    json_s.jsonDataSub.subscribe(res=>{
      this.jsonData = res; 
      if(this.jsonData != null){
        this.assignData();
      }
    })
  }

  ngOnInit() {
    this.href = this.router.url; 
    this.devUrl = this.devDomain+this.href;  
    this.activeRoute.params.subscribe((paramData) => {
      this.orgId = paramData.id;
    })
  } 

  ngAfterViewInit() {
    if(this.jsonData != null){
      this.assignData();
    }
  } 

  assignData(){
    if(!this.jsonData.daysToCollectCSV){ 
      this.dataunavailable = true;
    }
    else {
        this.daysToCollect = this.jsonData.daysToCollectCSV.days_to_collect;
        this.rollingSixMonth = this.jsonData.daysToCollectCSV.rolling_six_month;
        this.totalMonth = this.jsonData.daysToCollectCSV.month;  
        for(let i=0; i<=this.jsonData.daysToCollectCSV.rolling_six_month.length; i++){
          if(this.jsonData.daysToCollectCSV.rolling_six_month[i] != null){
            this.rollingAvgFirstDay = this.jsonData.daysToCollectCSV.rolling_six_month[i]; 
            break;
          }  
        }
        for(let i=this.jsonData.daysToCollectCSV.rolling_six_month.length-1; i>=0; i--){
          if(this.jsonData.daysToCollectCSV.rolling_six_month[i] != null){
            this.rollingAvgLastDay = this.jsonData.daysToCollectCSV.rolling_six_month[i];  
            break;
          }  
        }  
        this.rollingAvgResult = (1-(this.rollingAvgLastDay/ this.rollingAvgFirstDay))*100;     
    } 
    this.canvas = this.mychart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    new Chart(this.ctx, {
        type: 'line',
        data: {
            datasets: [{
                label: 'Days Overdue on Close Date',
                data: this.daysToCollect ,
                borderColor: "#F07F23",
                pointBorderColor: "#F07F23",
                pointHoverBackgroundColor: "#F07F23",
                backgroundColor: "#ffffff00",
                lineTension: 0,
                pointRadius: 5,
                pointHoverRadius: 5,
                pointBackgroundColor: '#F07F23',

            },
            {
                label: 'Rolling Average (6 months)',
                data: this.rollingSixMonth,
                borderColor: "#4CC3B6",
                backgroundColor: "#ffffff00",
                lineTension: 0,
                pointRadius: 5,
                pointHoverRadius: 5,
                pointBackgroundColor: '#4CC3B6',
            }],
            labels: this.totalMonth
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              deferred: {
                delay: 1000
              }
            },
            legend: {
                labels: {
                    fontColor: "#ffffff",
                    fontSize: 16,
                    fontFamily: "Poppins",
                    padding: 35,
                    usePointStyle: true,
                },
                position: 'bottom',
                align: 'center',
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontColor: "#ffffff",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        beginAtZero: true,
                        stepSize: 0,  
                        padding: 15,        
                    },
                    gridLines: {
                        color: "rgba(0, 0, 0, 0)",
                        zeroLineColor: "#FFFFFF",
                        tickMarkLength: 0,
                        zeroLineWidth: 3
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontColor: "#ffffff",
                        fontFamily: "Poppins",
                        fontSize: 16,
                        stepSize: 1,
                        beginAtZero: true,
                        padding: 15,
                    },
                    gridLines: { 
                        color: "rgba(0, 0, 0, 0)",
                        zeroLineColor: "#FFFFFF",
                        tickMarkLength: 0,
                        zeroLineWidth: 3
                    }
                }]
            }
        }
    });
  } 
}
