<div class="progress-bar-wrapper">
    <div class="d-flex align-items-center my-4">
        <span class="w-25">July 20</span>
        <div class="progress w-100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%">75%</div>
        </div>
    </div>
    <div class="d-flex align-items-center my-4">
        <span class="w-25">Oct 20</span>
        <div class="progress w-100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 34%">34%</div>
        </div>
    </div>
    <div class="d-flex align-items-center my-4">
        <span class="w-25">Jan 21</span>
        <div class="progress w-100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 66%">66%</div>
        </div>
    </div>
    <div class="d-flex align-items-center my-4">
        <span class="w-25">April 21</span>
        <div class="progress w-100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 18%">18%</div>
        </div>
    </div>
    <div class="d-flex align-items-center my-4">
        <span class="w-25">June 21</span>
        <div class="progress w-100">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 25%">25%</div>
        </div>
    </div>
</div>
