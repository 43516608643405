<div class="container overview-section">
    <div class="row no-gutters">
        <div class="col-md-12 header">
            <div class="d-flex justify-content-between">
                <h2 class="overview-heading wow animate__animated animate__fadeInLeft">Overview</h2>
                <button class="btn share-btn share-btn1 animate__animated animate__fadeInRight">
                    Share Via 
                    <share-buttons  
                        [theme]="'modern-light-theme'"
                        [include]="['linkedin','twitter','email']"
                        [show]="4"
                        [size]="1"
                        url= {{devUrl}}
                        [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                        [autoSetMeta]="false"
                    ></share-buttons>
                </button> 
            </div>
            <hr class="white-line">
        </div>
    </div>
    <div class="row no-gutters total-chased-section">
        <div class="col-xl-6 col-lg-6 col-md-12 col-12"> 
            <div class="d-flex flex-column align-items-center wow animate__animated  animate__fadeInUp">
                <img src="../../../assets/images/receivable-icon.svg" class="receivable-image" />
                <span class="total-chased-label">Total Chased and Closed</span> 
                <span class="total-amount">${{collectedBalance | number : fractionSize }}  </span>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-12"> 
            <div class="d-flex flex-column align-items-center wow animate__animated  animate__fadeInUp">
                <img src="../../../assets/images/payments-icon.svg" class="payments-image" />
                <span class="total-chased-label">Total Balance Chased</span> 
                <span class="total-amount">${{issuedBalance | number : fractionSize }}  </span>
            </div>
        </div>
    </div>
    <div class="row no-gutters data-section">
        <div class="col-md-4"> 
            <div class="content-wrapper d-flex flex-column wow animate__animated  animate__fadeInUp">
                <img src="../../../assets/images/hands.svg" class="hand-image" />
                <span class="lbl">Balance Chased<br /> and Closed</span> 
                <span class="data"> {{balanceChased + "%"}} </span> 
            </div>
        </div> 
        <div class="col-md-4"> 
            <div class="content-wrapper d-flex flex-column align-items-center wow animate__animated  animate__fadeInUp">
                <img src="../../../assets/images/communication.svg" class="comm-image" />
                <span class="lbl d-flex flex-column total-comm">
                    Total Comms Sent
                    <span class="data"> {{totalComms | number : fractionSize }} </span> 
                </span> 
            </div>
        </div> 
        <div class="col-md-4"> 
            <div class="content-wrapper d-flex flex-column align-items-center pl-5 wow animate__animated  animate__fadeInUp">
                <img src="../../../assets/images/Calendar-icon.svg" class="calendar-image" />
                <span class="lbl d-flex flex-column">Avg. Days Overdue<br /> Improvement
                <span class="data" *ngIf="overdueImprovementVisibility" > {{overdueImprovement + "%"}} </span> </span> 
            </div>
        </div> 
    </div>
    <div class="row no-gutters total-time-saved-section">
        <div class="col-md-12">
            <div class="d-flex justify-content-center align-items-center wow animate__animated  animate__fadeInUp">   
                <i class="fa fa-clock-o clock-icon" aria-hidden="true"></i> 
                <div class="d-flex flex-column">
                    <label for="" class="total-time">Total Time Saved</label>
                    <span class="data-label"><span class="total-time-saved">{{totalTimeSaved}}</span> Hours</span> 
                </div> 
            </div>
        </div>
        <!-- <div class="col-md-6"> 
            <div class="d-flex flex-column">
                <div class="d-flex d-column justify-content-evenly">
                    <div class="d-flex flex-column align-items-center mobile-view"> 
                        <circle-progress [percent]="timeSavedCountInYear"  [space]="-20" [radius]="42" [outerStrokeWidth]="20" [innerStrokeWidth]="20" [outerStrokeColor]="'#4CC3B6'" [innerStrokeColor]="'#DFDFDF'" [animation]="true" [imageHeight]="70" [imageWidth]="70" [animationDuration]="1000"> 
                        </circle-progress> 
                        <span class="data-label">Years</span> 
                        <span class="percentage-data"> {{ timeSavedCountInYear + "%"}} </span> 
                    </div> 
                    <div class="d-flex flex-column align-items-center mobile-view"> 
                        <circle-progress [percent]="timeSavedInCountMonth"  [space]="-20" [radius]="42" [outerStrokeWidth]  ="20" [innerStrokeWidth]="20" [outerStrokeColor]="'#4CC3B6'" [innerStrokeColor]="'#DFDFDF'" [animation]="true" [imageHeight]="70" [imageWidth]="70" [animationDuration]="1000"> 
                        </circle-progress> 
                        <span class="data-label">Months</span> 
                        <span class="percentage-data"> {{ timeSavedInCountMonth + "%"}} </span> 
                    </div> 
                    <div class="d-flex flex-column align-items-center mobile-view"> 
                        <circle-progress [percent]="timeSavedInCountHours"  [space]="-20" [radius]="42" [outerStrokeWidth]="20" [innerStrokeWidth]="20" [outerStrokeColor]="'#4CC3B6'" [innerStrokeColor]="'#DFDFDF'" [animation]="true" [imageHeight]="70" [imageWidth]="70"
                        [animationDuration]="1000"> 
                        </circle-progress> 
                        <span class="data-label">Hours</span> 
                        <span class="percentage-data"> {{ timeSavedInCountHours + "%"}} </span> 
                    </div> 
                </div>
            </div>
        </div> -->
        <!-- <div class="col-md-6"> 
            <div class="d-flex flex-column">
                <label for="" class="cash-flow wow animate__animated  animate__fadeInUp">Cash Flow Impact</label>
                <div class="d-flex d-column justify-content-around">
                    <div class="d-flex flex-column align-items-center mobile-view"> 
                        <circle-progress [percent]="cashFlowImpactCountInYear"  [space]="-20" [radius]="42" [outerStrokeWidth]="20" [innerStrokeWidth]="20" [outerStrokeColor]="'#F07F23'" [innerStrokeColor]="'#DFDFDF'" [animation]="true" [imageHeight]="70" [imageWidth]="70"
                        [animationDuration]="1000"> 
                        </circle-progress> 
                        <span class="data-label">Years</span> 
                        <span class="percentage-data"> {{cashFlowImpactCountInYear + "%"}} </span> 
                    </div> 
                    <div class="d-flex flex-column align-items-center mobile-view"> 
                        <circle-progress [percent]="cashFlowImpactCountInMonth"  [space]="-20" [radius]="42" [outerStrokeWidth]="20" [innerStrokeWidth]="20" [outerStrokeColor]="'#F07F23'" [innerStrokeColor]="'#DFDFDF'" [animation]="true" [imageHeight]="70" [imageWidth]="70"
                        [animationDuration]="1000"> 
                        </circle-progress> 
                        <span class="data-label">Months</span> 
                        <span class="percentage-data"> {{cashFlowImpactCountInMonth + "%"}} </span> 
                    </div> 
                    <div class="d-flex flex-column align-items-center mobile-view"> 
                        <circle-progress [percent]="cashFlowImpactCountInHours"  [space]="-20" [radius]="42" [outerStrokeWidth]="20" [innerStrokeWidth]="20" [outerStrokeColor]="'#F07F23'" [innerStrokeColor]="'#DFDFDF'" [animation]="true" [imageHeight]="70" [imageWidth]="70"
                        [animationDuration]="1000"> 
                        </circle-progress> 
                        <span class="data-label">Hours</span> 
                        <span class="percentage-data"> {{cashFlowImpactCountInHours + "%"}} </span> 
                    </div> 
                </div>
            </div>
        </div>  -->
    </div>
</div>
<app-outstanding></app-outstanding>
