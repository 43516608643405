import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartsRoutingModule } from './charts-routing.module';

import { CommsSentComponent } from './comms-sent/comms-sent.component';
import { YourCustomerChart2Component } from './your-customer-chart2/your-customer-chart2.component';
import { YourCustomerChart1Component } from './your-customer-chart1/your-customer-chart1.component';
import { OutstandingComponent } from './outstanding/outstanding.component';
import { OverviewComponent } from './overview/overview.component';
import { PayAfterReminderComponent } from './pay-after-reminder/pay-after-reminder.component';
import { PaymentDataComponent } from './payment-data/payment-data.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { QuarterChartComponent } from './quarter-chart/quarter-chart.component';
import { RemindersSentByDayComponent } from './reminders-sent-by-day/reminders-sent-by-day.component';
import { ChangeInOverdueComponent } from './change-in-overdue/change-in-overdue.component';
import { AccountsChartComponent } from './accounts-chart/accounts-chart.component';
import { InvoiceSentComponent } from './invoice-sent/invoice-sent.component';
import { RemindersSentComponent } from './reminders-sent/reminders-sent.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgCircleProgressModule } from 'ng-circle-progress'; 
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { UnavailableContentComponent } from './unavailable-content/unavailable-content.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
  YourCustomerChart1Component,
  InvoiceSentComponent,
  RemindersSentComponent,
  OutstandingComponent,
  CommsSentComponent,
  AccountsChartComponent,
  QuarterChartComponent,
  PaymentMethodComponent,
  PayAfterReminderComponent,
  PaymentDataComponent,
  ChangeInOverdueComponent,
  RemindersSentByDayComponent,
  YourCustomerChart1Component,
  YourCustomerChart2Component,
  InvoiceSentComponent,
  RemindersSentComponent,
  OverviewComponent,
  UnavailableContentComponent,
],
  imports: [
    CommonModule,
    ChartsRoutingModule,
    NgApexchartsModule,
    NgCircleProgressModule,
    ShareButtonsModule.withConfig({
      debug: true
    }),
    ShareIconsModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    CommsSentComponent,
    YourCustomerChart2Component,
    OutstandingComponent,
    OverviewComponent,
    PayAfterReminderComponent,
    PaymentDataComponent,
    PaymentMethodComponent,
    QuarterChartComponent,
    RemindersSentByDayComponent,
    ChangeInOverdueComponent,
    AccountsChartComponent,
    RemindersSentComponent,
    InvoiceSentComponent,
  ], 
})
export class ChartsModule { }
