import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';
import {
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexChart,
  ApexPlotOptions,
  ChartComponent
} from "ng-apexcharts";  

@Component({
  selector: 'app-payment-data',
  templateUrl: './payment-data.component.html',
  styleUrls: ['./payment-data.component.scss']
})
export class PaymentDataComponent implements OnInit {

  orgId: any;
  dataunavailable: boolean = false; 
  days_of_week: any = [];
  hour_of_day: any = [];
  total_payments: any;
  time: any = [];   
  jsonData: any; 
  paymentTotal: any;


  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  allCSVData = {};
  allData = {};
  finalData = {}
  time00AM: any = [];
  time01AM: any = [];
  time02AM: any = [];
  time03AM: any = [];
  time04AM: any = [];
  time05AM: any = [];
  time06AM: any = [];
  time07AM: any = [];
  time08AM: any = [];
  time09AM: any = [];
  time10AM: any = [];
  time11AM: any = []; 
  time12PM: any = [];
  time13PM: any = [];
  time14PM: any = [];
  time15PM: any = [];
  time16PM: any = [];
  time17PM: any = [];
  time18PM: any = [];
  time19PM: any = [];
  time20PM: any = [];
  time21PM: any = [];
  time22PM: any = [];
  time23PM: any = []; 
  
  AM00: any = {};
  AM01: any = {};
  AM02: any = {};
  AM03: any = {};
  AM04: any = {};
  AM05: any = {};
  AM06: any = {};
  AM07: any = {};
  AM08: any = {};
  AM09: any = {};
  AM10: any = {};
  AM11: any = {}; 
  PM12: any = {}; 
  PM13: any = {};
  PM14: any = {};
  PM15: any = {};
  PM16: any = {};
  PM17: any = {};
  PM18: any = {};
  PM19: any = {};
  PM20: any = {};
  PM21: any = {};
  PM22: any = {};
  PM23: any = {}; 
  
  dataMon: any = 0;
  dataTue: any = 0;
  dataWed: any = 0;
  dataThu: any = 0;
  dataFri: any = 0;
  dataSat: any = 0;
  dataSun: any = 0;
  
  constructor( private httpClient: HttpClient,  
    private activeRoute: ActivatedRoute,
    private router: Router,
    private json_s: CommanSerivceService ) {  
    json_s.jsonDataSub.subscribe(res=>{
      this.jsonData = res; 
      if(this.jsonData != null){
        this.assignData();
      } else {
        this.dataunavailable = true;
      }
    })
    for(let index=0; index <= 23; index++) {
      if(index<12){ 
        this.time.push(`${index} am`) ;
      } else { 
        this.time.push(`${index} pm`) ;
      } 
    } 
  }

  ngOnInit() {
    this.activeRoute.params.subscribe((paramData) => {
      this.orgId = paramData.id;
    })
  } 

  ngAfterViewInit() {
    if(this.jsonData != null){
      this.assignData();
    } else {
      this.dataunavailable = true;
    }
  }  
 
  assignData() {  
    if(this.jsonData.PaymentCSV){
      this.days_of_week = this.jsonData.PaymentCSV.days_of_week;
      this.hour_of_day = this.jsonData.PaymentCSV.hour_of_day;
      this.total_payments = this.jsonData.PaymentCSV.total_payments;  
      
      // Getting sum of numbers
      this.paymentTotal = this.total_payments.reduce(function(a, b){
          return a + b;
      }, 0);    

    //Require data store in object
      for(let i=0;i<this.total_payments.length;i++) { 
        if(!this.allData[i]){
          this.allData[i] = {} 
        }
        let data = (this.total_payments[i]/this.paymentTotal)*100; 
        for(let j=0; j<24; j++) {
          if(this.hour_of_day[i] == j) {  
            this.allData[i].finalData = {
              Day: this.days_of_week[i],
              Amount: data,
              Hour: this.hour_of_day[i]
            }
          } 
        }
      } 
      
    //12PM data    
    // add data for 12pm
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 12){
        this.time12PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        }) 
      }
    }     
    // add data as per day for 12PM
    for(let i=0;i<this.time12PM.length;i++) { 
      if(this.time12PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time12PM[i].y; 
      }  
      if(this.time12PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time12PM[i].y; 
      }  
      if(this.time12PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time12PM[i].y; 
      }  
      if(this.time12PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time12PM[i].y; 
      }  
      if(this.time12PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time12PM[i].y; 
      }  
      if(this.time12PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time12PM[i].y; 
      }  
      if(this.time12PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time12PM[i].y; 
      }   
    }   
    this.PM12 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]     
    
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 
  
    //11AM data
    // add data for 11AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 11){
        this.time11AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    }  
    // add data as per day for 11AM
    for(let i=0;i<this.time11AM.length;i++) { 
      if(this.time11AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time11AM[i].y; 
      }  
      if(this.time11AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time11AM[i].y; 
      }  
      if(this.time11AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time11AM[i].y; 
      }  
      if(this.time11AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time11AM[i].y; 
      }  
      if(this.time11AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time11AM[i].y; 
      }  
      if(this.time11AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time11AM[i].y; 
      }  
      if(this.time11AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time11AM[i].y; 
      }   
    }  
    this.AM11 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 
  
    //10AM data
    // add data for 10AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 10){
        this.time10AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 10AM
    for(let i=0;i<this.time10AM.length;i++) { 
      if(this.time10AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time10AM[i].y; 
      }  
      if(this.time10AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time10AM[i].y; 
      }  
      if(this.time10AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time10AM[i].y; 
      }  
      if(this.time10AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time10AM[i].y; 
      }  
      if(this.time10AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time10AM[i].y; 
      }  
      if(this.time10AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time10AM[i].y; 
      }  
      if(this.time10AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time10AM[i].y; 
      }   
    }  
    this.AM10 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //9AM data
    // add data for 9AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 9){
        this.time09AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 9AM
    for(let i=0;i<this.time09AM.length;i++) { 
      if(this.time09AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time09AM[i].y; 
      }  
      if(this.time09AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time09AM[i].y; 
      }  
      if(this.time09AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time09AM[i].y; 
      }  
      if(this.time09AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time09AM[i].y; 
      }  
      if(this.time09AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time09AM[i].y; 
      }  
      if(this.time09AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time09AM[i].y; 
      }  
      if(this.time09AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time09AM[i].y; 
      }   
    }  
    this.AM09 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0;
    
    //08AM data
    // add data for 08AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 8){
        this.time08AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 08AM
    for(let i=0;i<this.time08AM.length;i++) { 
      if(this.time08AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time08AM[i].y; 
      }  
      if(this.time08AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time08AM[i].y; 
      }  
      if(this.time08AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time08AM[i].y; 
      }  
      if(this.time08AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time08AM[i].y; 
      }  
      if(this.time08AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time08AM[i].y; 
      }  
      if(this.time08AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time08AM[i].y; 
      }  
      if(this.time08AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time08AM[i].y; 
      }   
    }  
    this.AM08 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0;
      
    //07AM data
    // add data for 07AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 7){
        this.time07AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    }  
    
    // add data as per day for 07AM
    for(let i=0;i<this.time07AM.length;i++) { 
      if(this.time07AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time07AM[i].y; 
      }  
      if(this.time07AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time07AM[i].y; 
      }  
      if(this.time07AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time07AM[i].y; 
      }  
      if(this.time07AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time07AM[i].y; 
      }  
      if(this.time07AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time07AM[i].y; 
      }  
      if(this.time07AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time07AM[i].y; 
      }  
      if(this.time07AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time07AM[i].y; 
      }   
    }  
    this.AM07 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]   
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //06AM data
    // add data for 06AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 6){
        this.time06AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 06AM
    for(let i=0;i<this.time06AM.length;i++) { 
      if(this.time06AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time06AM[i].y; 
      }  
      if(this.time06AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time06AM[i].y; 
      }  
      if(this.time06AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time06AM[i].y; 
      }  
      if(this.time06AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time06AM[i].y; 
      }  
      if(this.time06AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time06AM[i].y; 
      }  
      if(this.time06AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time06AM[i].y; 
      }  
      if(this.time06AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time06AM[i].y; 
      }   
    }  
    this.AM06 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //05AM data
    // add data for 05AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 5){
        this.time05AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    }   
    // add data as per day for 05AM
    for(let i=0;i<this.time05AM.length;i++) { 
      if(this.time05AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time05AM[i].y; 
      }  
      if(this.time05AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time05AM[i].y; 
      }  
      if(this.time05AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time05AM[i].y; 
      }  
      if(this.time05AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time05AM[i].y; 
      }  
      if(this.time05AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time05AM[i].y; 
      }  
      if(this.time05AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time05AM[i].y; 
      }  
      if(this.time05AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time05AM[i].y; 
      }   
    }  
    this.AM05 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]   
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //04AM data
    // add data for 04AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 4){
        this.time04AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 04AM
    for(let i=0;i<this.time04AM.length;i++) { 
      if(this.time04AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time04AM[i].y; 
      }  
      if(this.time04AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time04AM[i].y; 
      }  
      if(this.time04AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time04AM[i].y; 
      }  
      if(this.time04AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time04AM[i].y; 
      }  
      if(this.time04AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time04AM[i].y; 
      }  
      if(this.time04AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time04AM[i].y; 
      }  
      if(this.time04AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time04AM[i].y; 
      }   
    }  
    this.AM04 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0;
      
    //03AM data
    // add data for 03AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 3){
        this.time03AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 03AM
    for(let i=0;i<this.time03AM.length;i++) { 
      if(this.time03AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time03AM[i].y; 
      }  
      if(this.time03AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time03AM[i].y; 
      }  
      if(this.time03AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time03AM[i].y; 
      }  
      if(this.time03AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time03AM[i].y; 
      }  
      if(this.time03AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time03AM[i].y; 
      }  
      if(this.time03AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time03AM[i].y; 
      }  
      if(this.time03AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time03AM[i].y; 
      }   
    }  
    this.AM03 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //02AM data
    // add data for 02AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 2){
        this.time02AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 02AM
    for(let i=0;i<this.time02AM.length;i++) { 
      if(this.time02AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time02AM[i].y; 
      }  
      if(this.time02AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time02AM[i].y; 
      }  
      if(this.time02AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time02AM[i].y; 
      }  
      if(this.time02AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time02AM[i].y; 
      }  
      if(this.time02AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time02AM[i].y; 
      }  
      if(this.time02AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time02AM[i].y; 
      }  
      if(this.time02AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time02AM[i].y; 
      }   
    }  
    this.AM02 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //01AM data
    // add data for 01AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 1){
        this.time01AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 01AM
    for(let i=0;i<this.time01AM.length;i++) { 
      if(this.time01AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time01AM[i].y; 
      }  
      if(this.time01AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time01AM[i].y; 
      }  
      if(this.time01AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time01AM[i].y; 
      }  
      if(this.time01AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time01AM[i].y; 
      }  
      if(this.time01AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time01AM[i].y; 
      }  
      if(this.time01AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time01AM[i].y; 
      }  
      if(this.time01AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time01AM[i].y; 
      }   
    }  
    this.AM01 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0;
            
    //12AM data
    // add data for 12AM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 0){
        this.time00AM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 12AM
    for(let i=0;i<this.time00AM.length;i++) { 
      if(this.time00AM[i].x == 1) {
        this.dataMon = this.dataMon + this.time00AM[i].y; 
      }  
      if(this.time00AM[i].x == 2) {
        this.dataTue = this.dataTue + this.time00AM[i].y; 
      }  
      if(this.time00AM[i].x == 3) {
        this.dataWed = this.dataWed + this.time00AM[i].y; 
      }  
      if(this.time00AM[i].x == 4) {
        this.dataThu = this.dataThu + this.time00AM[i].y; 
      }  
      if(this.time00AM[i].x == 5) {
        this.dataFri = this.dataFri + this.time00AM[i].y; 
      }  
      if(this.time00AM[i].x == 6) {
        this.dataSat = this.dataSat + this.time00AM[i].y; 
      }  
      if(this.time00AM[i].x == 7) {
        this.dataSun = this.dataSun + this.time00AM[i].y; 
      }   
    }  
    this.AM00 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //01PM data
    // add data for 01PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 13){
        this.time13PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 01PM
    for(let i=0;i<this.time13PM.length;i++) { 
      if(this.time13PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time13PM[i].y; 
      }  
      if(this.time13PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time13PM[i].y; 
      }  
      if(this.time13PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time13PM[i].y; 
      }  
      if(this.time13PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time13PM[i].y; 
      }  
      if(this.time13PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time13PM[i].y; 
      }  
      if(this.time13PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time13PM[i].y; 
      }  
      if(this.time13PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time13PM[i].y; 
      }   
    }  
    this.PM13 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //02PM data
    // add data for 02PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 14){
        this.time14PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 02PM
    for(let i=0;i<this.time14PM.length;i++) { 
      if(this.time14PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time14PM[i].y; 
      }  
      if(this.time14PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time14PM[i].y; 
      }  
      if(this.time14PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time14PM[i].y; 
      }  
      if(this.time14PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time14PM[i].y; 
      }  
      if(this.time14PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time14PM[i].y; 
      }  
      if(this.time14PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time14PM[i].y; 
      }  
      if(this.time14PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time14PM[i].y; 
      }   
    }  
    this.PM14 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //03PM data
    // add data for 03PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 15){
        this.time15PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 03PM
    for(let i=0;i<this.time15PM.length;i++) { 
      if(this.time15PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time15PM[i].y; 
      }  
      if(this.time15PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time15PM[i].y; 
      }  
      if(this.time15PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time15PM[i].y; 
      }  
      if(this.time15PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time15PM[i].y; 
      }  
      if(this.time15PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time15PM[i].y; 
      }  
      if(this.time15PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time15PM[i].y; 
      }  
      if(this.time15PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time15PM[i].y; 
      }   
    }  
    this.PM15 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //04PM data
    // add data for 04PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 16){
        this.time16PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 04PM
    for(let i=0;i<this.time16PM.length;i++) { 
      if(this.time16PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time16PM[i].y; 
      }  
      if(this.time16PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time16PM[i].y; 
      }  
      if(this.time16PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time16PM[i].y; 
      }  
      if(this.time16PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time16PM[i].y; 
      }  
      if(this.time16PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time16PM[i].y; 
      }  
      if(this.time16PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time16PM[i].y; 
      }  
      if(this.time16PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time16PM[i].y; 
      }   
    }  
    this.PM16 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //05PM data
    // add data for 05PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 17){
        this.time17PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 05PM
    for(let i=0;i<this.time17PM.length;i++) { 
      if(this.time17PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time17PM[i].y; 
      }  
      if(this.time17PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time17PM[i].y; 
      }  
      if(this.time17PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time17PM[i].y; 
      }  
      if(this.time17PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time17PM[i].y; 
      }  
      if(this.time17PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time17PM[i].y; 
      }  
      if(this.time17PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time17PM[i].y; 
      }  
      if(this.time17PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time17PM[i].y; 
      }   
    }  
    this.PM17 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 
              
    //06PM data
    // add data for 06PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 18){
        this.time18PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 06PM
    for(let i=0;i<this.time18PM.length;i++) { 
      if(this.time18PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time18PM[i].y; 
      }  
      if(this.time18PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time18PM[i].y; 
      }  
      if(this.time18PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time18PM[i].y; 
      }  
      if(this.time18PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time18PM[i].y; 
      }  
      if(this.time18PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time18PM[i].y; 
      }  
      if(this.time18PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time18PM[i].y; 
      }  
      if(this.time18PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time18PM[i].y; 
      }   
    }  
    this.PM18 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //07PM data
    // add data for 07PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 19){
        this.time19PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 07PM
    for(let i=0;i<this.time19PM.length;i++) { 
      if(this.time19PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time19PM[i].y; 
      }  
      if(this.time19PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time19PM[i].y; 
      }  
      if(this.time19PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time19PM[i].y; 
      }  
      if(this.time19PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time19PM[i].y; 
      }  
      if(this.time19PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time19PM[i].y; 
      }  
      if(this.time19PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time19PM[i].y; 
      }  
      if(this.time19PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time19PM[i].y; 
      }   
    }  
    this.PM19 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 
    
    //08PM data
    // add data for 05PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 20){
        this.time20PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 05PM
    for(let i=0;i<this.time20PM.length;i++) { 
      if(this.time20PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time20PM[i].y; 
      }  
      if(this.time20PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time20PM[i].y; 
      }  
      if(this.time20PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time20PM[i].y; 
      }  
      if(this.time20PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time20PM[i].y; 
      }  
      if(this.time20PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time20PM[i].y; 
      }  
      if(this.time20PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time20PM[i].y; 
      }  
      if(this.time20PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time20PM[i].y; 
      }   
    }  
    this.PM20 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //09PM data
    // add data for 09PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 21){
        this.time21PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 09PM
    for(let i=0;i<this.time21PM.length;i++) { 
      if(this.time21PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time21PM[i].y; 
      }  
      if(this.time21PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time21PM[i].y; 
      }  
      if(this.time21PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time21PM[i].y; 
      }  
      if(this.time21PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time21PM[i].y; 
      }  
      if(this.time21PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time21PM[i].y; 
      }  
      if(this.time21PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time21PM[i].y; 
      }  
      if(this.time21PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time21PM[i].y; 
      }   
    }  
    this.PM21 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //10PM data
    // add data for 10PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 22){
        this.time22PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 10PM
    for(let i=0;i<this.time22PM.length;i++) { 
      if(this.time22PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time22PM[i].y; 
      }  
      if(this.time22PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time22PM[i].y; 
      }  
      if(this.time22PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time22PM[i].y; 
      }  
      if(this.time22PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time22PM[i].y; 
      }  
      if(this.time22PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time22PM[i].y; 
      }  
      if(this.time22PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time22PM[i].y; 
      }  
      if(this.time22PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time22PM[i].y; 
      }   
    }  
    this.PM22 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0; 

    //11PM data
    // add data for 11PM
    for(let i=0;i<this.days_of_week.length;i++) {
      if(this.allData[i].finalData.Hour == 23){
        this.time23PM.push({
          x: this.allData[i].finalData.Day , 
          y: this.allData[i].finalData.Amount
        })
      }
    } 
    // add data as per day for 11PM
    for(let i=0;i<this.time23PM.length;i++) { 
      if(this.time23PM[i].x == 1) {
        this.dataMon = this.dataMon + this.time23PM[i].y; 
      }  
      if(this.time23PM[i].x == 2) {
        this.dataTue = this.dataTue + this.time23PM[i].y; 
      }  
      if(this.time23PM[i].x == 3) {
        this.dataWed = this.dataWed + this.time23PM[i].y; 
      }  
      if(this.time23PM[i].x == 4) {
        this.dataThu = this.dataThu + this.time23PM[i].y; 
      }  
      if(this.time23PM[i].x == 5) {
        this.dataFri = this.dataFri + this.time23PM[i].y; 
      }  
      if(this.time23PM[i].x == 6) {
        this.dataSat = this.dataSat + this.time23PM[i].y; 
      }  
      if(this.time23PM[i].x == 7) {
        this.dataSun = this.dataSun + this.time23PM[i].y; 
      }   
    }  
    this.PM23 = [
      { x: "Sun", y: (this.dataSun).toFixed(2) }, 
      { x: "Mon", y: (this.dataMon).toFixed(2) },
      { x: "Tue", y: (this.dataTue).toFixed(2) },
      { x: "Wed", y: (this.dataWed).toFixed(2) },
      { x: "Thu", y: (this.dataThu).toFixed(2) },
      { x: "Fri", y: (this.dataFri).toFixed(2) },
      { x: "Sat", y: (this.dataSat).toFixed(2) }
    ]  
    this.dataMon = 0;
    this.dataTue = 0;
    this.dataWed = 0; 
    this.dataThu = 0; 
    this.dataFri = 0; 
    this.dataSat = 0; 
    this.dataSun = 0;  

    this.chartOptions = {
      series: [
        {
          name: "11 pm",
          data: this.PM23
        },
        {
          name: "10 pm",
          data: this.PM22
        },
        {
          name: "9 pm",
          data: this.PM21
        },
        {
          name: "8 pm",
          data: this.PM20
        },
        {
          name: "7 pm",
          data: this.PM19
        },
        {
          name: "6 pm",
          data: this.PM18
        },
        {
          name: "5 pm",
          data: this.PM17
        },
        {
          name: "4 pm",
          data: this.PM16
        },
        {
          name: "3 pm",
          data: this.PM15
        },
        {
          name: "2 pm",
          data: this.PM14
        },
        {
          name: "1 pm",
          data: this.PM13
        },
        {
          name: "12 pm",
          data: this.PM12
        },
        {
          name: "11 am", 
          data: this.AM11
        },
        {
          name: "10 am",
          data: this.AM10
        },
        {
          name: "9 am",
          data: this.AM09
        },
        {
          name: "8 am",
          data: this.AM08
        },
        {
          name: "7 am",
          data: this.AM07
        },
        {
          name: "6 am",
          data: this.AM06
        },
        {
          name: "5 am",
          data: this.AM05
        },
        {
          name: "4 am",
          data: this.AM04
        },
        {
          name: "3 am",
          data: this.AM03
        },
        {
          name: "2 am",
          data: this.AM02
        },
        {
          name: "1 am",
          data: this.AM01
        },
        {
          name: "12 am",
          data: this.AM00, 
        }
      ], 
      chart: {
        height: 550, 
        type: "heatmap",
        fontFamily: 'Poppins', 
        foreColor: '#335366'
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.1,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0.99,
                name: "0%-1%", 
                color: "#C7D5D8", 
              },
              {
                from: 1,
                to: 1.99,
                name: '1%-2%',
                color: "#57808C"
              },
              {
                from: 2,
                to: 2.99,
                name: '2%-3%',
                color: "#77B8D8"
              },
              {
                from: 3,
                to: 100,
                name: "3%-100%",
                color: "#4CC3B6"
              }
            ]
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      
    }; 
  }
  }
} 

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
};
