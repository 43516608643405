
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiCallService } from 'src/app/services/api-call.service';
import { CommanSerivceService } from '../../services/comman-serivce.service';
import { URLS } from '../../services/constant';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  
  isResetForm = false;

  registerForm: FormGroup; // form

  @Input() isReset = false;

  /**Variable for password validation */
  pass_v = { cap_letter: false,
             is_number: false,
             is_symbol: false,
             is_form_info: false }

  isLoad= false;


  constructor(private apiCallService: ApiCallService,
              private common: CommanSerivceService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute,
              ) {

      // Initialize Registration Form.
      this.registerForm = new FormGroup({
        accountingSoftware1: new FormControl('' ,[]),
        contactName: new FormControl(null ,[Validators.required, Validators.maxLength(25), this.common.noWhitespaceValidator]),
        lastName: new FormControl(null ,[Validators.required, Validators.maxLength(25), this.common.noWhitespaceValidator]),
        contactPhone: new FormControl(null ,[Validators.required, Validators.minLength(10) , Validators.maxLength(18) , Validators.pattern(/^[0-9]{10,18}$/) , this.common.noWhitespaceValidator]),
        contactEmail: new FormControl(null , [Validators.required, Validators.maxLength(50), Validators.email, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)]),
        companyName1: new FormControl('' , [Validators.maxLength(50)]),
        password: new FormControl(null , [Validators.required, Validators.maxLength(50), Validators.minLength(8),  this.common.noWhitespaceValidator]),
        confirmPassword: new FormControl(null , [Validators.required, Validators.maxLength(50), this.common.noWhitespaceValidator]),
        accountingSystem: new FormControl("DEMO"),
        targetSection: new FormControl("credit-management/scores"),
        noOfDaysTrial: new FormControl(0),
        noOfDaysCITrial: new FormControl(30),
        termsAndConditions: new FormControl(null, [Validators.requiredTrue]),
        mailingList1: new FormControl(null, [])
      });
  }

  @Output() modalClose : EventEmitter<any> = new EventEmitter<any>();



  closeModal() {
    // this.route.queryParams.subscribe(async params => {
    //   if(params && params.trigger_from=='header'){
    //     this.router.navigate([{outlets: {modal: null}}]);
    //   } else {
    //     let url = this.router.url;
    //     url = url.replace('(modal:register)', '');
    //     const newUrl = url.split('/').filter(x =>  (x !== '') );
    //     var s = []
    //     for await (let x of newUrl) {
    //       if(x == ''){
    //         return;
    //       }
    //       s.push(decodeURI(x));
    //     }
    //     setTimeout(() => {
    //       if (s.length == 1 && s[0]=='search'){
    //         this.router.navigate(['/'])
    //         return;
    //       }
    //       this.router.navigate(s.length > 0 ? s : ["/"]);
    //     }, 200);
    //   }
    // });
  }


  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.isReset==true){
      this.registerForm.reset(this.registerForm);
    }
  }

/**
 * On submit form
 */
  onSubmit(){
    if(this.registerForm.invalid) {
      this.common.validateAllFormFields(this.registerForm);
    } else {
        let formJson = {};
        formJson['companyName1'] = this.registerForm.get('companyName1').value;
        formJson['contactName'] = this.registerForm.get('contactName').value + ' ' + this.registerForm.get('lastName').value;;
        formJson['contactEmail'] = this.registerForm.get('contactEmail').value;
        formJson['password'] = this.registerForm.get('password').value;
        formJson['contactPhone'] = this.registerForm.get('contactPhone').value;
        formJson['accountingSystem'] = this.registerForm.get('accountingSystem').value;
        formJson['targetSection'] = this.registerForm.get('targetSection').value;
        formJson['noOfDaysTrial'] = this.registerForm.get('noOfDaysTrial').value;
        formJson['noOfDaysCITrial'] = this.registerForm.get('noOfDaysCITrial').value; 
        const registerFormData = new FormData();
        registerFormData.append('trialRequest' , JSON.stringify(formJson)); 
        this.apiCall(registerFormData)
    }
  }

/**
 * Password validation
 */
  passwordValidator(){
    var numbers = /[0-9]/;
    var cap_letter = /[A-Z]/;
    var symbol = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    this.pass_v.cap_letter = cap_letter.test(this.registerForm.get('password').value);
    this.pass_v.is_number = numbers.test(this.registerForm.get('password').value);
    this.pass_v.is_symbol = symbol.test(this.registerForm.get('password').value);
    let x = this.registerForm.value;
    let y = this.registerForm.get('password').value;
    this.pass_v.is_form_info = !((x.contactEmail!= null && x.contactEmail.includes(y)) || (x.contactName!= null && x.contactName.includes(y)) || (x.lastName!= null && x.lastName.includes(y)) || (x.companyName1!= null && x.companyName1.includes(y)));
  }


  /**
   * For API call
   */
  apiCall(formData){
    this.isLoad = true; 
    this.apiCallService.post(URLS.REGISTER , formData).subscribe((res: any) => {
      this.isLoad = false;
      if (res.status == 'error'){
        this.toastr.error(res.message);
      } else {
        if(this.registerForm.get('mailingList1').value){
          this.callAfterRegister(res);
        } else{
          window.top.location.href = res.data;
        }
      }
    } , (err => {
      console.log(err)
      this.isLoad = false;
    }));
  }


/**
 * on check mailing list this is triggered.
 * @param res (registration API response)
 */
  callAfterRegister(res){
    this.isLoad = true;
    let formJson = {};
        formJson['companyName1'] = this.registerForm.get('companyName1').value;
        formJson['firstName'] = this.registerForm.get('contactName').value;
        formJson['lastName'] = this.registerForm.get('lastName').value;
        formJson['email'] = this.registerForm.get('contactEmail').value;
        formJson['phone'] = this.registerForm.get('contactPhone').value;
        formJson['AR Software'] = this.registerForm.get('accountingSoftware1').value;

    this.apiCallService.postWebhook(URLS.AFTER_REGISTER , JSON.stringify(formJson)).subscribe((x: any) => {
      this.isLoad = false;
      window.top.location.href = res.data;
    } , (err => {
      console.log(err)
      this.isLoad = false;
      window.top.location.href = res.data;
    }));
  }


}
