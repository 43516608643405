<div id="chart" *ngIf="chartOptions && chartOptions.series">
  <h3 class="chart-header wow animate__animated animate__fadeInUp"> Payments by day/time </h3>
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [dataLabels]="chartOptions.dataLabels"
    [plotOptions]="chartOptions.plotOptions" 
  ></apx-chart>
</div>
<div *ngIf="!chartOptions"> 
  <app-unavailable-content></app-unavailable-content>
</div> 
