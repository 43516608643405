<div *ngIf="dataunavailable; else elseBlock" class="p-5">
    <app-unavailable-content></app-unavailable-content>
</div> 
<ng-template #elseBlock>
    <img src="../../../assets/images/Papers-Rockets-swirls.svg" class="Papers-Rockets-img wow animate__animated animate__pulse animate__delay-1s animate__repeat-3" />
    <img src="../../../assets/images/Mobile-Papers-Rockets.svg" class="Mobi-Papers-Rockets-img wow animate__animated animate__pulse animate__delay-1s animate__repeat-3" />
    <div class="comms-chart-wrapper">        
        <canvas id="myChart" width="700" height="450" class="mt-5" #mychart></canvas>
    </div> 
</ng-template>
