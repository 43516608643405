import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-pay-after-reminder',
  templateUrl: './pay-after-reminder.component.html',
  styleUrls: ['./pay-after-reminder.component.scss']
})
export class PayAfterReminderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  
  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d'); 
    
    new Chart(this.ctx, {
      type: 'horizontalBar',
      data: {
          datasets: [{
              label: 'Days to Pay', 
              data: [1, 3, 2.5, 6],   
              categoryPercentage: 0.9,
              barPercentage: 0.9,
              backgroundColor: "#C7D5D8",
              hoverBackgroundColor: "#aec0c4"
          }],
          labels: ['Reminder 1', 'SMS 1', 'Reminder 2', 'Statement'], 
      },
      options: { 
        responsive: true,
        plugins: {
         deferred: {  
          delay: 1000
         }
        },
        legend: {
          display: true,
          labels: {
            fontColor: "#335366",
            fontSize: 12, 
            padding: 15,
            usePointStyle: true,
          },
          position: 'bottom',
          align: 'end'
        },
        scales: {
            yAxes: [{
              stacked: true,
              ticks: { 
                fontColor: "#335366",
                fontSize: 14,
                fontFamily: 'Poppins',  
                padding: 10,            
              },
              gridLines: { 
                lineWidth: 1,
                color: "rgba(0, 0, 0, 0)", 
                tickMarkLength: 0, 
              } 
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#335366",
                    fontSize: 14,
                    fontFamily: 'Poppins',
                    padding: 20, 
                    stepSize: 2,
                    beginAtZero: true,
                    max: 8
                },
                gridLines: {
                  color: "#4CC3B6",
                  tickMarkLength: 0,
                  borderDash: [14, 9], 
                }
            }]
        }
      }
    });
  }
}
