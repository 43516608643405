import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  
  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d');
  
  new Chart(this.ctx, {
    type: 'pie',
    data: {
      labels: ['Simply Paid', 'Card', 'EFT'],
      datasets: [
        {
          label: 'Dataset 1',
          data: [50, 30, 70], 
          backgroundColor:  ['#335266', '#F07F23', '#4CC3B6'],
          
        }
      ] 
    },
    options: { 
        responsive: true,
        plugins: {
         deferred: {  
          delay: 1000
         }
        },
        legend: {
            labels: {
              fontColor: "#335366",
              fontSize: 12, 
              padding: 15,
              usePointStyle: true,
            },
            position: 'top', 
            align: 'center'
        }, 
    }
  });
  }

}
