import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import 'chartjs-plugin-deferred';


@Component({
  selector: 'app-your-customer-chart1',
  templateUrl: './your-customer-chart1.component.html',
  styleUrls: ['./your-customer-chart1.component.scss']
})
export class YourCustomerChart1Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  
  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d');
    
  new Chart(this.ctx, {
    type: 'bar',
    data: {
        datasets: [{
          label: 'Sales ($)', 
          data: [20, 25, 10, 35, 65],  
          backgroundColor: '#23B4BA',             
          borderColor: "#F07F23",
          pointBorderColor: "#F07F23",   
          barThickness : 45,
          categoryPercentage: 0.9,
          barPercentage: 0.9
      }],
        labels: ['July 20', 'February', 'March', 'April', 'May'],
        
    },
    options: { 
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
       deferred: {  
        delay: 1000
       }
      },
      legend: {
        display: true,
        labels: {
            fontColor: "#ffffff",
            fontSize: 12,
            padding: 25,
            usePointStyle: true,
        },
        position: 'right', 
        align: 'start'
      },
      scales: {
          yAxes: [{ 
            ticks: { 
              fontColor: "#ffffff",
              fontSize: 16,
              fontFamily: 'Poppins',
              stepSize: 0, 
              suggestedMax: -40, 
              padding: 10,     
            },
            gridLines: { 
              lineWidth: 1,
              color: "#4CC3B6",
              borderDash: [12, 7], 
              tickMarkLength: 0, 
            } 
          }],
          xAxes: [{  
              ticks: {
                  fontColor: "#ffffff",
                  fontSize: 16,
                  fontFamily: 'Poppins',
                  padding: 20,     
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)", 
                tickMarkLength: 0
              }
          }]
      }
    }
  });
}

}
