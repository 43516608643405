<div class="modal fade in"  id="myModal" data-backdrop="static" >
    <div class="modal-dialog modal-lg modal-dialog-centered ">
       <div class="modal-content modal_bg">
          <!-- Modal Header -->
          <div class="modal-header header_modal">
             <h3 class="modal-title text-center w-100 mt-3">Get started today and get 3 free credit scores</h3>
             <span type="button" class="close text-white outline_none close_btn" data-dismiss="modal" (click)="closeModal()">&times;</span>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
             <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="row mt-0 pt-0">
                   <div class="col-md-6 px-5 col-sm-12">
                      <label class="lable__" for="name">*First Name</label>
                      <input autocomplete="off" type="text" class="form-control" id="name" formControlName="contactName" placeholder="First Name">
                      <ng-container *ngIf="registerForm.get('contactName').touched">
                         <div class="text-danger error" *ngIf="registerForm.get('contactName').invalid && !registerForm.hasError('required', 'contactName')">
                            First name is invalid.
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('required', 'contactName')">
                            First name is required.
                         </div>
                      </ng-container>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                      <label class="lable__" for="last_name">*Last Name</label>
                      <input autocomplete="off" type="text" class="form-control" id="last_name" formControlName="lastName" placeholder="Last Name">
                      <ng-container *ngIf="registerForm.get('lastName').touched">
                         <div class="text-danger error" *ngIf="registerForm.get('lastName').invalid && !registerForm.hasError('required', 'lastName')">
                            Last name is invalid.
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('required', 'lastName')">
                            Last name is required.
                         </div>
                      </ng-container>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                      <label class="lable__" for="email">*Email</label>
                      <input autocomplete="off" type="text" class="form-control" id="email" formControlName="contactEmail" placeholder="Email">
                      <ng-container *ngIf="registerForm.get('contactEmail').touched">
                         <div class="text-danger error" *ngIf="registerForm.get('contactEmail').invalid && !registerForm.hasError('required', 'contactEmail')">
                            Email is invalid.
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('required', 'contactEmail')">
                            Email is required.
                         </div>
                      </ng-container>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                      <label class="lable__" for="phone_number">*Phone Number</label>
                      <input autocomplete="off" type="text" class="form-control" id="phone_number"  formControlName="contactPhone" placeholder="Phone Number">
                      <ng-container *ngIf="registerForm.get('contactPhone').touched">
                         <div class="text-danger error" *ngIf="registerForm.get('contactPhone').invalid && !registerForm.hasError('required', 'contactPhone')">
                            Phone number is invalid.
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('required', 'contactPhone')">
                            Phone number is required.
                         </div>
                      </ng-container>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                    <label class="lable__" for="companyName">Company Name</label>
                    <input  autocomplete="off" type="text" class="form-control" id="companyName" formControlName="companyName" placeholder="Company Name">
                    <ng-container *ngIf="registerForm.get('companyName').touched">
                       <div class="text-danger error" *ngIf="registerForm.get('companyName').invalid && !registerForm.hasError('required', 'companyName')">
                          Company name is invalid.
                       </div>
                       <div class="text-danger error" *ngIf="registerForm.hasError('required', 'companyName')">
                          Company name is required.
                       </div>
                    </ng-container>
                 </div>
  
                   <div class="col-md-6 px-5 col-sm-12">
                         <label for="accountingSoftware" class="lable__">Accounting Software</label>
                         <select class="form-control"  id="accountingSoftware" formControlName="accountingSoftware">
                            <option>MYOB</option>
                            <option>Xero</option>
                            <option>Sage</option>
                            <option>Pronto</option>
                            <option>JCurve</option>
                            <option>Others</option>
                         </select>
                         <ng-container *ngIf="registerForm.get('accountingSoftware').touched">
                            <div class="text-danger error" *ngIf="registerForm.get('accountingSoftware').invalid && !registerForm.hasError('required', 'accountingSoftware')">
                               Accounting Software is invalid.
                            </div>
                            <div class="text-danger error" *ngIf="registerForm.hasError('required', 'accountingSoftware')">
                               Accounting Software is required.
                            </div>
                         </ng-container>
                   </div>
  
                   <div class="col-md-6 px-5 col-sm-12">
                      <label class="lable__" for="create_password">*Create Password</label>
                      <div class="dropdown">
                         <input  type="password" (keyup)="passwordValidator()" autocomplete="off" class="form-control dropdown-toggle" formControlName="password" id="create_password" placeholder="Password" data-toggle="dropdown">
                         <div class="dropdown-menu drop_menu  w-100">
                            <div class="row p-3 justify-content-center">
                               <div class="col-md-12">
                                  <div class="row">
                                     <hr class="hr_class px-2" [ngClass]="{'correct': pass_v.cap_letter, 'incorrect': !pass_v.cap_letter}">
                                     <hr class="hr_class px-2" [ngClass]="{'correct': pass_v.is_form_info, 'incorrect': !pass_v.is_form_info}">
                                     <hr class="hr_class px-2" [ngClass]="{'correct': pass_v.is_number, 'incorrect': !pass_v.is_number}">
                                     <hr class="hr_class px-2" [ngClass]="{'correct': pass_v.is_symbol, 'incorrect': !pass_v.is_symbol}">
                                  </div>
                               </div>
                               <span class=" check_box text-left w-100">
                               <img src="../../../assets/images/tick.svg"  alt="tick /checkmark" *ngIf="pass_v.cap_letter" class="circle_color" width="18px" height="18px">
                               <img src="../../../assets/images/close.svg"  alt="close"  *ngIf="!pass_v.cap_letter" class="circle_color" width="18px" height="18px">
                               <span>Capital Letter</span>
                               </span>
                               <span class="check_box  text-left w-100">
                               <img src="../../../assets/images/tick.svg"  alt="tick /checkmark" *ngIf="pass_v.is_form_info" class="circle_color" width="18px" height="18px">
                               <img src="../../../assets/images/close.svg"  alt="close"  *ngIf="!pass_v.is_form_info" class="circle_color" width="18px" height="18px">
                               <span>Does not contain your personal details</span>
                               </span>
                               <span class="check_box  text-left w-100">
                               <img src="../../../assets/images/tick.svg"  alt="tick /checkmark" *ngIf="pass_v.is_number" class="circle_color" width="18px" height="18px">
                               <img src="../../../assets/images/close.svg"  alt="close"  *ngIf="!pass_v.is_number" class="circle_color" width="18px" height="18px">
                               <span>Contain a number</span>
                               </span>
                               <span class="check_box  text-left w-100">
                               <img src="../../../assets/images/tick.svg"  alt="tick /checkmark" *ngIf="pass_v.is_symbol" class="circle_color" width="18px" height="18px">
                               <img src="../../../assets/images/close.svg"  alt="close"  *ngIf="!pass_v.is_symbol" class="circle_color" width="18px" height="18px">
                               <span>Contain a symbol</span>
                               </span>
                            </div>
                         </div>
                      </div>
                      <ng-container *ngIf="registerForm.get('password').touched">
                         <div class="text-danger error" *ngIf="registerForm.get('password').invalid && !registerForm.hasError('required', 'password') && (registerForm.hasError('minlength') || registerForm.hasError('maxlength'))">
                            Password is invalid.
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('minlength' , 'password')">
                            Minimum 8 length password required
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('maxlength' , 'password')">
                            Maximum 50 length password required
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('required', 'password')">
                            Password is required.
                         </div>
                      </ng-container>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                      <label class="lable__" for="confirm_password">*Confirm Password</label>
                      <input type="password" autocomplete="off" class="form-control"  formControlName="confirmPassword" id="confirm_password" placeholder="Confirm Password">
                      <ng-container *ngIf="registerForm.get('confirmPassword').touched">
                         <div class="text-danger error" *ngIf="registerForm.get('confirmPassword').invalid && !registerForm.hasError('required', 'confirmPassword')">
                            Confirm password is invalid.
                         </div>
                         <div class="text-danger error" *ngIf="registerForm.hasError('required', 'confirmPassword')">
                            Confirm password is required.
                         </div>
                         <div class="text-danger error" *ngIf="(registerForm.get('confirmPassword').value != registerForm.get('password').value) && !registerForm.get('confirmPassword').invalid && !registerForm.hasError('required', 'confirmPassword')">
                            Password not matched
                         </div>
                      </ng-container>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                      <div class="form-group form-check mt-4">
                         <input type="checkbox" class="form-check-input largerCheckbox" formControlName="termsAndConditions" id="termsAndConditions">
                         <label class="form-check-label check_label ml-3" for="termsAndConditions">I Accept the <a target="_blank" href="https://ezycollect.com.au/terms-conditions"><u>Terms and Conditions</u></a></label>
                         <ng-container *ngIf="registerForm.get('termsAndConditions').touched">
                            <div class="text-danger error" *ngIf="registerForm.get('termsAndConditions').invalid && !registerForm.hasError('required', 'termsAndConditions')">
                               Please accept terms & conditions
                            </div>
                            <div class="text-danger error" *ngIf="registerForm.hasError('required', 'termsAndConditions')">
                               Please accept terms & conditions
                            </div>
                         </ng-container>
                      </div>
                   </div>
                   <div class="col-md-6 px-5 col-sm-12">
                      <div class="form-group form-check mt-4">
                         <input type="checkbox" class="form-check-input largerCheckbox" formControlName="mailingList"  id="mailingList">
                         <label class="form-check-label check_label ml-3" for="mailingList">I would like to join the mailing list</label>
                         <ng-container *ngIf="registerForm.get('mailingList').touched">
                            <div class="text-danger error" *ngIf="registerForm.get('mailingList').invalid && !registerForm.hasError('required', 'mailingList')">
                               Please accept our mailing list
                            </div>
                            <div class="text-danger error" *ngIf="registerForm.hasError('required', 'mailingList')">
                               Please accept our mailing list
                            </div>
                         </ng-container>
                      </div>
                   </div>
                </div>
                <div class="row justify-content-center mt-3">
                   <div class="col-md-12 col-12 px-5">
                     <div class="row justify-content-center">
                      <button  type="submit" [disabled]="isLoad" class="btn btn-md rounded register_btn">
                        CREATE MY ACCOUNT
                        <div class="spinner-grow spinner" role="status" *ngIf="isLoad">
                           <span class="sr-only">Loading...</span>
                        </div>
                     </button>
                     </div>
                   </div>
                </div>
             </form>
          </div>
       </div>
    </div>
  </div>