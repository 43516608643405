import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';

@Component({
  selector: 'app-change-in-overdue',
  templateUrl: './change-in-overdue.component.html',
  styleUrls: ['./change-in-overdue.component.scss']
})
export class ChangeInOverdueComponent implements OnInit {

  constructor(private json_s: CommanSerivceService) { }

  ngOnInit(): void {
    this.json_s.jsonData 
  }
  ngOnChanges(changes: SimpleChanges): void { 
    this.json_s.jsonData 
  }


  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;

  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    new Chart(this.ctx, {
      type: 'horizontalBar',
      data: {
          datasets: [{
              label: 'Sales',
              data: [100, 300, 250, 450, 650],
              categoryPercentage: 0.8,
              barPercentage: 0.8,
              backgroundColor: "#57808C",
          }],
          labels: ['July 20', 'Oct 20', 'Jan 21', 'April 21', 'June 21'],
      },
      options: {
        responsive: true,
        plugins: {
         deferred: {
          delay: 1000
         }
        },
        legend: {
          display: false
        },
        scales: {
            yAxes: [{
              stacked: true,
              ticks: {
                fontColor: "#335366",
                fontSize: 16,
                fontFamily: 'Poppins',
                padding: 15,
              },
              gridLines: {
                tickMarkLength: 0,
                drawOnChartArea: false,
                lineWidth: 0,
              }
            }],
            xAxes: [{
              stacked: true,
              display: false,
                ticks: {
                    fontColor: "#335366",
                    fontSize: 12,
                    fontFamily: 'Poppins',
                    padding: 20,
                    stepSize: 250,
                    beginAtZero: true,
                    max: 700
                },
                gridLines: {
                  color: "#4CC3B6",
                  tickMarkLength: 0,
                  borderDash: [14, 9],
                }
            }]
        }
      }
    });
  }
}
