import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  timeSavedCountInYear:number = 0;
  timeSavedInCountMonth:number = 0;
  timeSavedInCountHours:number = 0;
  cashFlowImpactCountInYear:number = 0;
  cashFlowImpactCountInMonth:number = 0;
  cashFlowImpactCountInHours:number = 0;
  balanceChased:any = "0";
  commsSentCount:number = 0;
  overdueImprovement:any = '0';
  overdueImprovementVisibility = true;
  fractionSize: number;
  totalComms: number = 0;
  commsData: any = [];
  collectedBalanceData: any = [];
  collectedBalance: number = 0;
  issuedBalanceData: any = [];
  issuedBalance: number = 0;
  jsonData: any;
  orgId: any;
  totalTimeSaved: any = "0";
  rollingAvgFirstDay: any = "0";
  rollingAvgLastDay: any = "0";
  public href: string = "";
  devDomain: string = "https://eofyreport.ezycollect.com.au"; 
  devUrl: string = "";


  constructor(private httpClient: HttpClient,
    private router: Router,
    private json_s: CommanSerivceService,
    private activeRoute: ActivatedRoute) {
    json_s.jsonDataSub.subscribe(res=>{
      this.jsonData = res; 
      if(this.jsonData != null){
        this.assignData();
      }
    })
  }

  ngOnInit() {
    this.href = this.router.url; 
    this.devUrl = this.devDomain+this.href;  
    this.activeRoute.params.subscribe((paramData) => {
      this.orgId = paramData.id;
    })
    
  } 

  ngAfterViewInit() {
    if(this.jsonData != null){
      this.assignData();
    }
  } 

  assignData(){  
    if(this.jsonData) {
      this.commsData = this.jsonData.commsCSV.total_comms;
      this.collectedBalanceData = this.jsonData.invoiceCSV.totalBalanceCollected;
      this.issuedBalanceData = this.jsonData.invoiceCSV.totalBalanceIssued;
      for(let i=0; i< this.commsData.length ; i++ ){
        this.totalComms += this.commsData[i]; 
      }  
      for(let i=0; i< this.collectedBalanceData.length ; i++ ){
        this.collectedBalance += this.collectedBalanceData[i]; 
      }  
      for(let i=0; i< this.issuedBalanceData.length ; i++ ){
        this.issuedBalance += this.issuedBalanceData[i]; 
      } 
      //Balance Chased and Closed
      this.balanceChased = ((this.collectedBalance/this.issuedBalance)*100).toFixed(2);

      //Avg. Days Overdue Improvement   
      for(let i=0; i<=this.jsonData.daysToCollectCSV.rolling_six_month.length; i++){
        if(this.jsonData.daysToCollectCSV.rolling_six_month[i] != null){
          this.rollingAvgFirstDay = this.jsonData.daysToCollectCSV.rolling_six_month[i]; 
          break;
        }  
      }
      for(let i=this.jsonData.daysToCollectCSV.rolling_six_month.length-1; i>=0; i--){
        if(this.jsonData.daysToCollectCSV.rolling_six_month[i] != null){
          this.rollingAvgLastDay = this.jsonData.daysToCollectCSV.rolling_six_month[i];  
          break;
        }  
      }    
      this.overdueImprovement = ((1-(this.rollingAvgLastDay/this.rollingAvgFirstDay))*100).toFixed(2); 
      if(parseInt(this.overdueImprovement) < 0){ 
        this.overdueImprovementVisibility = false;
      }
    }
    if(this.jsonData.TimeSavedHoursCSV){
      //Total Time Saved
      this.totalTimeSaved = this.jsonData.TimeSavedHoursCSV.time_saved_hours[0].toFixed(2); 
      
    }
  }

  timeSavedInYearStop:any = setInterval (() => {
    this.timeSavedCountInYear++;
    if(this.timeSavedCountInYear == 30) {
      clearInterval(this.timeSavedInYearStop);
    }
  },2)

  timeSavedInCountMonthStop:any = setInterval (() => {
    this.timeSavedInCountMonth++;
    if(this.timeSavedInCountMonth == 50) {
      clearInterval(this.timeSavedInCountMonthStop);
    }
  },2)

  timeSavedInCountHoursStop:any = setInterval (() => {
    this.timeSavedInCountHours++;
    if(this.timeSavedInCountHours == 22) {
      clearInterval(this.timeSavedInCountHoursStop);
    }
  },2)

  cashFlowImpactCountInYearStop:any = setInterval (() => {
    this.cashFlowImpactCountInYear++;
    if(this.cashFlowImpactCountInYear == 30) {
      clearInterval(this.cashFlowImpactCountInYearStop);
    }
  },2)

  cashFlowImpactCountInMonthStop:any = setInterval (() => {
    this.cashFlowImpactCountInMonth++;
    if(this.cashFlowImpactCountInMonth == 50) {
      clearInterval(this.cashFlowImpactCountInMonthStop);
    }
  },2)

  cashFlowImpactCountInHoursStop:any = setInterval (() => {
    this.cashFlowImpactCountInHours++;
    if(this.cashFlowImpactCountInHours == 22) {
      clearInterval(this.cashFlowImpactCountInHoursStop);
    }
  },2)

  // balanceChasedStop:any = setInterval (() => {
  //   this.balanceChased++;
  //   if(this.balanceChased == 98) {
  //     clearInterval(this.balanceChasedStop);
  //   }
  // },2)
 
  // commSentCountStop:any = setInterval(() => {
  //   this.commsSentCount++;  
  //   if(this.commsSentCount == this.totalComms) {
  //     clearInterval(this.commSentCountStop);
  //   }
  // },1)

  // overdueImprovementStop:any = setInterval (() => {
  //   this.overdueImprovement++;
  //   if(this.overdueImprovement == 27) {
  //     clearInterval(this.overdueImprovementStop);
  //   }
  // },3)

}
