<div class="payemnt-section-wrapper"> 
    <div class="content">
        <div> 
            Help your business get paid faster with seamless checkouts from invoice, email & SMS. 
            <br /> <br /> 
            Get
            <ul>
                <li>automated payment writebacks into your ERP</li>
                <li>integrated digital ‘pay now’ buttons</li>
            </ul> 
            Get paid faster with ezyCollect Payments 
        </div>
        <div class="d-flex lp-wrapper">
            <img src="../../../../assets/images/SimplyPaid.svg" alt="laptop-img" class="laptop" />
        </div>
    </div>
</div> 
<button class="btn book-btn" data-toggle="modal" data-target="#bookDemoForm">Book a demo</button>
 