import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import 'chartjs-plugin-deferred'; 
import { ActivatedRoute, Router } from '@angular/router';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';

@Component({
  selector: 'app-reminders-sent',
  templateUrl: './reminders-sent.component.html',
  styleUrls: ['./reminders-sent.component.scss']
})
export class RemindersSentComponent implements OnInit {

  issuedInvoices: any[] = [];
  issuedInvoicesMonth: any[] = [];
  orgId: any;
  jsonData: any;
  dataunavailable: boolean = false; 
  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  
  constructor( private activeRoute: ActivatedRoute,
    private router: Router,
    private json_s: CommanSerivceService) {
    json_s.jsonDataSub.subscribe(res=>{
      this.jsonData = res; 
      if(this.jsonData != null){
        this.assignData();
      }
    })
  }

  ngOnInit() {  
    this.activeRoute.params.subscribe((paramData) => {
      this.orgId = paramData.id;
    })
  }  

  ngAfterViewInit() {
    if(this.jsonData != null){
      this.assignData();
    }
  }  

  assignData(){ 
    if(!this.jsonData){ 
      this.dataunavailable = true;
    }
    else {
        this.issuedInvoices = this.jsonData.commsCSV.total_comms; 
        this.issuedInvoicesMonth = this.jsonData.commsCSV.month;
    }   
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d'); 
    new Chart(this.ctx, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'Reminder Sent',  
          data: this.issuedInvoices,
          backgroundColor: ['#BECCD1','#57808C','#335266','#4C7288','#5FA0C1','#91CBE8','#4CC3B6','#FFE1AB','#FFC764','#E8A721','#F07F23','#EC4C28','#EC4C28'],             
          borderColor: "#F07F23",
          pointBorderColor: "#F07F23",  
          categoryPercentage: 0.9,
          barPercentage: 0.9
        }],
        labels: this.issuedInvoicesMonth,  
      },
      options: { 
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          deferred: {  
            delay: 1000
          }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 25,
                bottom: 0
            }
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: { 
              fontColor: "#335366",
              fontSize: 16,
              fontFamily: 'Poppins',
              stepSize: 0,
              beginAtZero: true,   
              padding: 18,            
            },
            gridLines: { 
              lineWidth: 1,
              color: "#4CC3B6",
              borderDash: [12, 7],  
              zeroLineColor: "#335266",
              tickMarkLength: 0,
              zeroLineWidth: 4
            } 
          }],
          xAxes: [{ 
            ticks: {
                fontColor: "#335366",
                fontSize: 16,
                fontFamily: 'Poppins',
                padding: 20,     
            },
            gridLines: {
              color: "rgba(0, 0, 0, 0)",  
              zeroLineColor: "#335266",
              tickMarkLength: 0,
              zeroLineWidth: 4
            }
          }]
        },        
        tooltips: {
            enabled: true
        },
        hover: {
            animationDuration: 1
        },
        animation: { 
          onComplete: function () {
            var chartInstance = this.chart,
            ctx = chartInstance.ctx;
            ctx.textAlign = 'center';
            ctx.fillStyle = "#335366";
            ctx.textBaseline = 'bottom'; 
            this.data.datasets.forEach(function (dataset, i) {
                var meta = chartInstance.controller.getDatasetMeta(i);
                meta.data.forEach(function (bar, index) {
                    var data = dataset.data[index];
                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                });
            });
          }
        }
      }
    }); 
  } 


}
