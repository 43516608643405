
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';
import { Component } from '@angular/core';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from '../environments/environment';
import { rendererTypeName } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ezyCollect';
  orgId: any;
  fileUrl: any;
  collectedInvoices: any;
  fileData: any;
  ency_key: any;


constructor( private afStorage: AngularFireStorage,
  private route: ActivatedRoute,
  private router: Router,
  private httpClient: HttpClient,
  private c_service: CommanSerivceService) {

    this.route.params.subscribe(res=>{ 
    })
  }

ref: AngularFireStorageReference;
task: AngularFireUploadTask;



ngOnInit() { 
  this.route.params.subscribe(res=>{ 
  }) 
}
ngAfterViewInit(): void { 
  this.route.params.subscribe(res=>{ 
  })
}



  async red(){ 
      const path = 'dev-ar-report/' + '574.json'
      await this.getURL(path);
      this.getJsonData(this.fileUrl);  
  }
   
getURL(path){
  return new Promise((res) => {
  this.afStorage.ref(path).getDownloadURL()
      .subscribe(url => {
        this.fileUrl = url; 
        return this.fileUrl;
    })
  });
}
 
getJsonData(url){
  this.httpClient.get<any>(url).subscribe(res => {
    this.c_service.jsonData = res[this.orgId];
  });
}
 
  encode(value) {
    const encodedData = CryptoJS.AES.encrypt(JSON.stringify(value), environment.ACCESS_KEY).toString();
    return encodedData.trim();
  }
 
  decode(value) {
    const bytes = CryptoJS.AES.decrypt(value, environment.ACCESS_KEY);
    const decodedData = bytes.toString(CryptoJS.enc.Utf8);
    return decodedData;
  }





}
