import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';

@Component({
  selector: 'app-quarter-chart',
  templateUrl: './quarter-chart.component.html',
  styleUrls: ['./quarter-chart.component.scss']
})
export class QuarterChartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  
  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d');
  
  new Chart(this.ctx, {
    type: 'line',
    data: {
        datasets: [{
            label: 'DSO',
            data: [10, 50, 30, 70], 
            borderColor: "#F07F23",
            pointBorderColor: "#F07F23",
            pointHoverBackgroundColor: "#F07F23",
            backgroundColor: "#ffffff00",
            lineTension: 0,      
            pointRadius: 5,
            pointHoverRadius: 5, 
            pointBackgroundColor: '#F07F23', 
              
        },
        {
          label: 'Average Days to Pay',
          data: [50, 15, 55, 10], 
          borderColor: "#4CC3B6",
          backgroundColor: "#ffffff00",
          lineTension: 0,                 
          pointRadius: 5,
          pointHoverRadius: 5, 
          pointBackgroundColor: '#4CC3B6', 
      }],
        labels: ['QTR 1', 'QTR 2', 'QTR 3', 'QTR 4']
    },
    options: { 
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
         deferred: {  
          delay: 1000
         }
        },
        legend: {
            labels: {
              fontColor: "#335366",
                fontSize: 15,
                padding: 25,
                usePointStyle: true,
            },
            position: 'bottom', 
            align: 'end'
        },
        scales: {
            yAxes: [{
                ticks: {
                  display: false, 
                    stepSize: 0, 
                    suggestedMax: 75,                          
                },
                gridLines: { 
                    lineWidth: 1,
                    color: "#4CC3B6",
                    borderDash: [8, 4], 
                    tickMarkLength: 0, 
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#335366",
                    fontSize: 16,
                    stepSize: 1, 
                    padding: 0,      
                },
                gridLines: {
                  lineWidth: 1,
                  color: "#4CC3B6",
                  borderDash: [8, 4], 
                  tickMarkLength: 0, 
                }
            }]
        }
    }
  });
  }
}
