import { Component, OnInit } from '@angular/core';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';
import { ApiCallService } from 'src/app/services/api-call.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  title = 'ezyCollect';
  orgId: any;
  fileUrl: any;
  collectedInvoices: any;
  fileData: any;
  ency_key: any;
  isNotFound: boolean;
  s = { res: 574 };
  mainData: any;
  jsonData: any;
  orgName: any;
  public href: string = '';
  devDomain: string = 'https://eofyreport.ezycollect.com.au';
  prodDomain: string = 'https://eofyreport.ezycollect.com.au';
  devUrl: string = '';
  keyArray: any = [];
  allURLData: any = [];

  constructor(
    private afStorage: AngularFireStorage,
    private route: ActivatedRoute,
    private serializer: UrlSerializer,
    private router: Router,
    private httpClient: HttpClient,
    private c_service: CommanSerivceService,
    private titleService: Title,
    private api_call_service: ApiCallService
  ) {
    this.route.params.subscribe(async (res) => {
      this.orgId = res.id;
      await this.getQueryParams();
      if (!this.isNotFound) {
        const path = 'prod-ar-report/' + this.orgId + '.json';
        await this.getURL(path);
      } else {
        this.router.navigate(['/not-found']);
      }
    });

    c_service.jsonDataSub.subscribe((res) => {
      this.jsonData = res;
      if (this.jsonData != null) {
        this.assignData();
        this.titleService.setTitle(
          `${this.jsonData?.invoiceCSV?.org_name[0]} - ezyCollect EOFY Accounts Receivables Report`
        );
      } else {
        this.titleService.setTitle(
          `ezyCollect EOFY Accounts Receivables Report`
        );
      }
    });
  }

  assignData() {
    if (this.jsonData.invoiceCSV)
      this.orgName = this.jsonData.invoiceCSV.org_name[0];
  }

  ref: AngularFireStorageReference;
  task: AngularFireUploadTask;

  ngOnInit() {
    this.href = this.router.url;
    this.devUrl = this.devDomain + this.href;
    this.httpClient
      .get<any>('../assets/files/orgCSVData.txt')
      .subscribe((res) => {
        this.keyArray = res;
        // console.log(this.keyArray);

        for (let i = 0; i < this.keyArray.length; i++) {
          let id = parseInt(this.keyArray[i].orgid);
          let encrypt = this.encode(id);
          let decrypt = this.decode(encrypt);
          const tree = this.router.createUrlTree([id], {
            queryParams: { ency_key: encrypt },
          });
          let try1 = this.serializer.serialize(tree);
          let url = this.prodDomain + try1;
          this.allURLData[i] = {
            Key: this.keyArray[i].orgid,
            averageOverdueDaysImprovement:
              this.keyArray[i].overdueImprovementData,
            URL: url,
          };
        }
        // console.log(this.allURLData.length);

        // this.downloadFile(this.allURLData, '2022UrlCSVFile');
        // this.downloadFile(this.allURLData, 'overDueCSVFile');
      });
  }

  downloadFile(data, filename = 'data') {
    let csvData = this.ConvertToCSV(data, [
      'Key',
      'averageOverdueDaysImprovement',
      'URL',
    ]);
    // console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + '';
      for (let index in headerList) {
        let head = headerList[index];
        line += ',' + array[i][head];
      }
      str += line + '\r\n';
    }
    return str;
  }

  // method is use for encrypt the value using crypto method.
  encode(value) {
    const encodedData = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      environment.ACCESS_KEY
    ).toString();
    return encodedData.trim();
  }

  // method is use for decrypt the value using crypto method.
  decode(value) {
    const bytes = CryptoJS.AES.decrypt(value, environment.ACCESS_KEY);
    const decodedData = bytes.toString(CryptoJS.enc.Utf8);
    return decodedData;
  }

  getQueryParams() {
    return new Promise((resolve, rej) => {
      this.route.queryParamMap.subscribe((res: any) => {
        let d_val = this.decode(res.params.ency_key);
        if (this.orgId != d_val) {
          this.isNotFound = true;
          resolve(this.isNotFound);
        } else {
          this.isNotFound = false;
          resolve(this.isNotFound);
        }
      });
    });
  }

  /**
   * This method used for get firebase url of json file.
   * @returns url
   */
  getURL(path) {
    return new Promise((res) => {
      this.api_call_service
        .getURLHandler(
          `https://api-hunter.ezycollect.com.au/s3Bucket/api/getS3SignedURL?file_name=${
            this.orgId + '.json'
          }&destination=${'prod-ar-report'}`
        )
        .subscribe((resp: any) => {
          this.fileUrl = resp.data.signed_url;
          this.getJsonData(this.fileUrl);
          return this.fileUrl;
        });
    });
    // return new Promise((res) => {
    //   this.afStorage
    //     .ref(path)
    //     .getDownloadURL()
    //     .subscribe((url) => {
    //       this.fileUrl = url;
    //       console.log('this.fileUrl', this.fileUrl);
    //       this.getJsonData(this.fileUrl);
    //       return this.fileUrl;
    //     });
    // });
  }

  /**
   * This method used to store json data in service variable
   * @returns void
   */
  getJsonData(url) {
    this.httpClient.get<any>(url).subscribe((res) => {
      this.c_service.triggerSubChange(res);
    });
  }
}
