import { Component, OnInit } from '@angular/core';   
import { HttpClient } from '@angular/common/http';
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

  commsSentCount:number = 0;
  totalInvoiceCount:number = 0;
  totalClosedInvoiceCount:number = 0;
  fractionSize: number;
  totalInvoiceData: any = [];
  fractionSize22: any;
  invoiceCount: number = 0; 
  invoiceData: any = [];
  commsData: any = [];
  closeInvoiceData: any = [];
  totalInvoices: number = 0;
  totalComms: number = 0;
  totalCloseInvoice: number = 0;
  jsonData: any;
  orgId: any;
  hoursData: any = "0";
  fullTimeDays: any = "0";
  dollarValue: any;
  dollarAmount: any = 0;
  public href: string = "";
  devDomain: string = "https://eofyreport.ezycollect.com.au"; 
  devUrl: string = "";

  constructor(private allData: CommanSerivceService, private httpClient: HttpClient,
    private router: Router,
    private json_s: CommanSerivceService,
    private activeRoute: ActivatedRoute ) {
      json_s.jsonDataSub.subscribe(res=>{
        this.jsonData = res; 
        if(this.jsonData != null){
          this.assignData();
        }
      })
    }

    ngOnInit() {
      this.href = this.router.url; 
      this.devUrl = this.devDomain+this.href; 
      this.activeRoute.params.subscribe((paramData) => {
        this.orgId = paramData.id;
      })
    } 
  
    ngAfterViewInit() {
      if(this.jsonData != null){
        this.assignData();
      }
    } 

    assignData() {  
      if(this.jsonData){
        this.invoiceData = this.jsonData.invoiceCSV.issuedInvoices;
        this.commsData = this.jsonData.commsCSV.total_comms;
        this.closeInvoiceData = this.jsonData.invoiceCSV.totalInvoiceCollected;
        for(let i=0; i< this.invoiceData.length ; i++ ){
          this.totalInvoices += this.invoiceData[i]; 
        }  
        for(let i=0; i< this.commsData.length ; i++ ){
          this.totalComms += this.commsData[i]; 
        }  
        for(let i=0; i< this.closeInvoiceData.length ; i++ ){
          this.totalCloseInvoice += this.closeInvoiceData[i]; 
        }    
      }
      if(this.jsonData.TimeSavedHoursCSV){
        this.hoursData = this.jsonData.TimeSavedHoursCSV.time_saved_hours[0].toFixed(2); 
        var fulltime = this.hoursData/8;
        this.fullTimeDays = fulltime.toFixed(2);
        this.dollarValue = this.fullTimeDays * 270;
        this.dollarAmount = Math.round(this.dollarValue); 
      }
  }


  //Communication sent count
  commSentCountStop: any = setInterval(() => {
    this.commsSentCount++;  
    if(this.commsSentCount == this.totalComms) {
      clearInterval(this.commSentCountStop);
    }
  },1)

  //Total invoice count
  totalInvoiceCountStop: any = setInterval (() => {
    this.totalInvoiceCount++;
    if(this.totalInvoiceCount == this.totalInvoices) {
      clearInterval(this.totalInvoiceCountStop);
    } 
  },1)

  //Total closed invoice count
  totalClosedInvoiceCountStop: any = setInterval (() => {
    this.totalClosedInvoiceCount++;
    if(this.totalClosedInvoiceCount == this.totalCloseInvoice) {
      clearInterval(this.totalClosedInvoiceCountStop);
    }
  },1)

  
}
