
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unavailable-content',
  templateUrl: './unavailable-content.component.html',
  styleUrls: ['./unavailable-content.component.scss']
})
export class UnavailableContentComponent implements OnInit {
  
  constructor() { }
  
  ngOnInit(): void {

  } 
}
