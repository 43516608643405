import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart } from 'chart.js';
import 'chartjs-plugin-deferred';

@Component({
  selector: 'app-reminders-sent-by-day',
  templateUrl: './reminders-sent-by-day.component.html',
  styleUrls: ['./reminders-sent-by-day.component.scss']
})
export class RemindersSentByDayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;
  
  ngAfterViewInit() {
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d');
    
    new Chart(this.ctx, {
      type: 'bar',
      data: {
          datasets: [
            {
              label: "Sunday",
              backgroundColor: "#C7D5D8",
              data: [5],  
            },
            {
              label: "Monday",
              backgroundColor: "#335266",
              data: [2], 
            },
            {
              label: "Tuesday",
              backgroundColor: "#77B8D8",
              data: [5], 
            },
            {
              label: "Wednesday",
              backgroundColor: "#4CC3B6",
              data: [6], 
            },
            {
              label: "Thursday",
              backgroundColor: "#F07F23",
              data: [3], 
            },
            {
              label: "Friday",
              backgroundColor: "#E8A721",
              data: [5], 
            },  
            {
              label: "Saturday",
              backgroundColor: "#EC4C28",
              data: [2], 
            }, 
            ],
      },
      options: { 
        responsive: true, 
        plugins: {
         deferred: {  
           delay: 1000
         }
       },
        legend: {
          display: true,
          labels: {
            fontColor: "#335366",
            fontSize: 12, 
            padding: 40,
            usePointStyle: true,
          },
          position: 'bottom',
          align: 'center'
        },
        scales: {
            yAxes: [{
              ticks: { 
                display: false    
              },
              gridLines: { 
                lineWidth: 1,
                color: "#4CC3B6",
                borderDash: [12, 7], 
                tickMarkLength: 0, 
              } 
            }],
            xAxes: [{
                ticks: {
                  display: false 
                },
                gridLines: {
                  color: "rgba(0, 0, 0, 0)", 
                  tickMarkLength: 0
                }
            }]
        },       
      }
    });
  }
}
