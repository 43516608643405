
<div class="outstandings-section" >
    <div class="container">
        <div class="row no-gutters">
            <div class="col-md-12">
                <div class="d-flex justify-content-between">
                    <h2 class="outstandings-heading  wow animate__animated  animate__fadeInLeft">Overdue Outstandings</h2>
                    <h2 class="overview-heading">Overview</h2>
                    <button class="btn share-btn text-white animate__animated animate__fadeInRight">
                        Share Via 
                        <share-buttons  
                            [theme]="'modern-light-theme'"
                            [include]="['linkedin','twitter','email']"
                            [show]="4"
                            [size]="1"
                            url= {{devUrl}}
                            [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                            [autoSetMeta]="false"
                        ></share-buttons>
                    </button> 
                </div>
                <hr class="white-line">
            </div>
        </div>
        <div *ngIf="dataunavailable; else elseBlock">
            <app-unavailable-content></app-unavailable-content>
        </div>
        <ng-template #elseBlock>
            <div class="Overdue-bar-wrapper">
                <canvas id="myChart" width="700" height="350" #mychart></canvas>
            </div>
            <div class="d-flex flex-column text-white outstandings-content  wow animate__animated  animate__fadeIn">
                <span class="bold-text">Your overdue outstandings have improved over the last 12 months.</span>
                <span class="light-text">-Average of {{rollingAvgFirstDay | number : '.2-2' }} days over rolling 6 months which has been reduced to {{rollingAvgLastDay | number : '.2-2' }} days.</span>
                <span class="light-text">-Resulting in {{ rollingAvgResult | number : '.2-2' }}% of average overdue days improvement</span>
            </div>
        </ng-template>
    </div>
</div>
