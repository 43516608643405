import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import 'chartjs-plugin-deferred';   
import { CommanSerivceService } from 'src/app/services/comman-serivce.service';

@Component({
  selector: 'app-comms-sent',
  templateUrl: './comms-sent.component.html',
  styleUrls: ['./comms-sent.component.scss']
})
export class CommsSentComponent implements OnInit {
  
  orgId: any;
  jsonData: any;
  dataunavailable: boolean = false; 
  totalComms: any = [];
  totalMonth: any = [];
  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart:any;  

  constructor( private activeRoute: ActivatedRoute, private router: Router, private json_s: CommanSerivceService){ 
    json_s.jsonDataSub.subscribe(res => {
      this.jsonData = res; 
      if(this.jsonData != null) {
        this.assignData();
      }
    })
  } 
  
  ngOnInit() {  
    this.activeRoute.params.subscribe((paramData) => {
      this.orgId = paramData.id;
    }) 
  } 

  ngAfterViewInit() {
    if(this.jsonData != null){
      this.assignData(); 
    }
  }
  
  assignData() {  
    if(!this.jsonData){ 
      this.dataunavailable = true;
    }
    else {
        this.totalComms = this.jsonData.commsCSV.total_comms; 
        this.totalMonth = this.jsonData.commsCSV.month;
    } 
    this.canvas = this.mychart.nativeElement; 
    this.ctx = this.canvas.getContext('2d'); 
    new Chart(this.ctx, {
      type: 'bar',
      data: {
          datasets: [{
              label: 'Comms Sent',
              data: this.totalComms,  
              pointBorderColor: "#F07F23", 
              backgroundColor: "#57808C",
              lineTension: 0,      
              pointRadius: 5,
              pointHoverRadius: 5, 
              pointBackgroundColor: '#F07F23', 
          }],
          labels: this.totalMonth
      },
      options: { 
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          deferred: {  
            delay: 1000
          }
        },
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 25,
                bottom: 0
            }
        },
        legend: {
            labels: {
                fontColor: "#335366",
                fontSize: 14,
                usePointStyle: true,
            },
            position: 'bottom', 
            align: 'end'
        },
        scales: {
            yAxes: [{
              ticks: {
                fontColor: "#335366",
                fontSize: 16,
                fontFamily: 'Poppins',
                stepSize: 0,
                beginAtZero: true,      
                padding: 15
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0)",
                zeroLineColor: "#335366",
                tickMarkLength: 0,
                zeroLineWidth: 3
              } 
            }],
            xAxes: [{
                ticks: {
                    fontColor: "#335366",
                    fontSize: 16,
                    fontFamily: 'Poppins',
                    padding: 15,
                    stepSize: 1,
                    beginAtZero: true,
                },
                gridLines: {
                  color: "rgba(0, 0, 0, 0)",
                  zeroLineColor: "#335366",
                  tickMarkLength: 0,
                  zeroLineWidth: 3
                }
            }]
        },        
        tooltips: {
            enabled: true
        },
        hover: {
            animationDuration: 1
        },
        animation: { 
          onComplete: function () {
            var chartInstance = this.chart,
                ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.fillStyle = "#335366";
                ctx.textBaseline = 'bottom'; 
                this.data.datasets.forEach(function (dataset, i) {
                    var meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        var data = dataset.data[index];
                        ctx.fillText(data, bar._model.x, bar._model.y - 5);
                    });
                });
            }
        }
      }
    });  
  }
} 
