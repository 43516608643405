import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {

  constructor(private http: HttpClient) { }
  
  /**
   * API of post
   * @param url
   * @param data
   */
   post(url ,data): Observable<any>{
    return this.http.post<any>(url , data)
  }


  /**
   * API of post of webhook
   * @param url
   * @param data
   */
  postWebhook(url ,data): Observable<any>{
    return this.http.post<any>(url , data )
  }

  /**
   * 
   * @param url 
   * @returns 
   */
   getURLHandler(url: string): Observable<any>{
    return this.http.get<any>(url)
  }

}
