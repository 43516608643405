<app-header></app-header>
<div class="home-background">
    <div class="row no-gutters">
        <div class="col-md-12">
            <div>
                <div class="content-wrapper text-white">
                    <div class="text-right">
                        <button class="btn share-btn animate__animated animate__fadeInRight">
                            Share Via 
                            <share-buttons class="share-button"  
                                [theme]="'modern-light-theme'"
                                [include]="['linkedin','twitter','email']"
                                [show]="4"
                                [size]="1"
                                url= {{devUrl}}
                                [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                                [autoSetMeta]="false"
                            ></share-buttons>
                        </button> 
                    </div>
                    <h4 class="sub-heading animate__animated  animate__fadeInUp">Happy EOFY!</h4>
                    <h1 class="page-heading animate__animated  animate__fadeInUp">End of financial <br/>year AR report </h1>
                    <p class="fy-label animate__animated  animate__fadeInUp">FY 2022-23</p>
                </div>
                <!-- Banner image -->
                <div class="banner-img mobibanner-img"></div>
            </div>
            <!-- Banner bottom round shape image -->
            <img class="pg-btm-img" src="../../../assets/images/pg-btm.svg" />
        </div>
    </div>
</div>
<div class="row no-gutters rww-group-wrapper">
    <div class="col-md-3">
        <img class="rww-image" src="../../../assets/images/rww-img.svg" />
    </div>
    <div class="col-md-9">
        <div class="content-wrapper scrollX-hidden">
            <h3 class="rww-heading wow animate__animated animate__fadeInRight">Hey there, <span>{{ orgName }}</span></h3>
            <p class="rww-content wow animate__animated animate__fadeInRight">
                We wanted to take a second to look back on your AR year, and help you celebrate a few wins! Here’s to the unsung heroes of business, the AR & Finance teams
            </p>
            <h5 class="ezycollect-team wow animate__animated animate__fadeInRight">-From your ezyCollect Team</h5>
        </div>
    </div>
</div>
<app-overview></app-overview> 
<app-communication></app-communication>