import { environment } from '../../environments/environment';


// Base url constant
export const BASEURL = environment.api_url;
export const WEBHOOK = environment.webhook; 


export const MESSAGE = {
    ERROR_MSG: 'Invalid request',
    REGISTER_S: 'User registered successfully'
  };
  
  
  // Global constant file
  export const URLS = Object.freeze({
      REGISTER: BASEURL + 'registerTrialOrganization?isAjax=true', 
      AFTER_REGISTER: WEBHOOK
  });