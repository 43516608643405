import { ChartsModule } from './components/charts/charts.module';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeComponent } from './components/home/home.component';
import { CommunicationComponent } from './components/communication/communication.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgApexchartsModule } from 'ng-apexcharts';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { NotFoundComponent } from './not-found/not-found.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component'; 
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons'; 

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    CommunicationComponent,
    HomeComponent,
    FooterComponent,
    NotFoundComponent,
    RegisterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgApexchartsModule,
    AngularFireModule.initializeApp(environment.firebase_Integrate),
    AngularFireStorageModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgCircleProgressModule.forRoot({
      "outerStrokeLinecap": "butt",
      "imageSrc": "./assets/images/progress-arrow.svg",
      "showImage": true,
      "showBackground": false}),
      ShareButtonsModule.withConfig({
        debug: true
      }),
      ShareIconsModule
  ],  
  providers: [Title],
  bootstrap: [AppComponent]
})
export class AppModule { }
