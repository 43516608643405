<div class="communication-section">
    <div class="gray-bg-image">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between scrollX-hidden">
                        <h2 class="section-heading wow animate__animated  animate__fadeInLeft">Communications</h2>
                        <button class="btn share-btn share-btn1 wow animate__animated animate__fadeInRight">
                            Share Via 
                            <share-buttons  
                                [theme]="'modern-light-theme'"
                                [include]="['linkedin','twitter','email']"
                                [show]="4"
                                [size]="1"
                                url= {{devUrl}}
                                [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                                [autoSetMeta]="false"
                            ></share-buttons>
                        </button> 
                    </div>
                    <hr class="white-line">
                </div>
            </div>
            <div class="total-data-section">
                <div class="desktop-only">
                    <div class="row no-gutters mb-5">
                        <div class="col-md-4 d-flex flex-column justify-content-center align-items-end">
                            <div class="total-comm-label">Total comms sent</div>
                            <div class="comm-data"> {{totalComms | number : fractionSize }} </div> 
                        </div>
                        <div class="col-md-4 d-flex justify-content-center align-items-center">
                            <div class="comm-content">
                                Email communications sent<br /> over a period of 12 months
                            </div>
                        </div>
                        <div class="col-md-4">
                            <img src="../../../assets/images/comm-image.svg" class="comm-img" />
                        </div>
                    </div>
                </div>
                <div class="mobile-only">
                    <div class="row no-gutters mb-5">
                        <div class="col-md-4">
                            <img src="../../../assets/images/comm-image.svg" class="comm-img" />
                        </div>
                        <div class="col-md-4 d-flex flex-column justify-content-center align-items-start">
                            <div class="total-comm-label">Total</div>
                            <div class="comm-data"> {{totalComms | number : fractionSize }} </div>
                        </div>
                        <div class="col-md-4 d-flex align-items-center">
                            <div class="comm-content">
                                Email communications sent<br /> over a period of 12 months
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters mb-4">
                    <div class="col-md-4 d-flex flex-column justify-content-center align-items-end mobi-alignment">
                        <div class="total-comm-label">Total Invoices</div>
                        <div class="comm-data">  {{totalInvoices | number : fractionSize }} </div>
                    </div>
                    <div class="col-md-8">
                        <img src="../../../assets/images/total-invoices.svg" class="total-invoices" />
                        <img src="../../../assets/images/Invoices-icon.svg" class="invoice-icon" />
                    </div>
                </div>
                <div class="row no-gutters mb-4">
                    <div class="col-md-4 d-flex flex-column justify-content-center align-items-end mobi-alignment">
                        <div class="total-comm-label">Total Invoices Closed</div>
                        <div class="comm-data"> {{totalCloseInvoice | number : fractionSize }} </div>
                    </div>
                    <div class="col-md-8">
                        <img src="../../../assets/images/Invoices-closed.svg" class="closed-invoices" />
                        <img src="../../../assets/images/right-tick.svg" class="right-tick" />
                    </div>
                </div>
            </div>
            <div >
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-center comm-header-heading scrollX-hidden">
                            <h2 class="section-heading wow animate__animated  animate__fadeInLeft">Communications Sent by Month</h2>
                            <button class="btn share-btn share-btn1 wow animate__animated animate__fadeInRight">
                                Share Via 
                                <share-buttons  
                                    [theme]="'modern-light-theme'"
                                    [include]="['linkedin','twitter','email']"
                                    [show]="4"
                                    [size]="1"
                                    url= {{devUrl}}
                                    [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                                    [autoSetMeta]="false"
                                ></share-buttons>
                            </button> 
                        </div>
                        <hr class="white-line">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="communications-sent-section">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-md-12">
                    <app-comms-sent></app-comms-sent>
                    <p class="comm-sent-content wow animate__animated  animate__fadeIn">
                        Communications have been sent at a steady pace, saving time every month. <br> If we consider sending an email with the correct invoices attached takes about 5 minutes, these saved over <span>{{ hoursData }} hours</span> of continuous email or <span>{{ fullTimeDays }} full-time days</span> chasing customers all day long - no breaks allowed.
                    </p>
                    <h3 class="comm-head wow animate__animated  animate__fadeIn">
                        That means <span>${{ dollarAmount | number : fractionSize }}</span> saved in 12 months just from the manual effort!
                    </h3>
                    <div class="comm-note wow animate__animated  animate__fadeIn">(this number considers the salary of an AR Officer to be 70k annually)</div>
                </div>
            </div>
            <div class="accounts-section">
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between align-items-center accounts-header scrollX-hidden">
                            <h2 class="section-heading wow animate__animated  animate__fadeInLeft">Accounts</h2>
                            <button class="btn share-btn share-btn1 wow animate__animated animate__fadeInRight">
                                Share Via 
                                <share-buttons  
                                    [theme]="'modern-light-theme'"
                                    [include]="['linkedin','twitter','email']"
                                    [show]="4"
                                    [size]="1"
                                    url= {{devUrl}}
                                    [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                                    [autoSetMeta]="false"
                                ></share-buttons>
                            </button> 
                        </div>
                        <hr class="white-line mt-0">
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <h3 class="chart-header wow animate__animated animate__fadeInUp"> Invoices Sent </h3>
                        <app-invoice-sent></app-invoice-sent>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <h3 class="chart-header wow animate__animated animate__fadeInUp"> Payments Collected </h3>
                        <app-accounts-chart></app-accounts-chart>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="shade-bg">
        <div class="container">
            <!-- <div class="row no-gutters">
                <div class="col-md-12">
                    <h3 class="chart-header wow animate__animated animate__fadeInUp"> DSO & Days to Pay by Quarter </h3>
                    <app-quarter-chart></app-quarter-chart>
                </div>
            </div>  -->
            <div class="row no-gutters">
                <div class="col-md-12">
                    <h3 class="chart-header wow animate__animated animate__fadeInUp"> Reminders Sent </h3>
                    <app-reminders-sent></app-reminders-sent>
                </div>
            </div>
            <div class="row no-gutters">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between align-items-center comm-header-heading scrollX-hidden">
                        <h2 class="section-heading wow animate__animated animate__fadeInLeft">Your Payments Story</h2>
                        <button class="btn share-btn share-btn1 wow animate__animated animate__fadeInRight">
                            Share Via 
                            <share-buttons  
                                [theme]="'modern-light-theme'"
                                [include]="['linkedin','twitter','email']"
                                [show]="4"
                                [size]="1"
                                url= {{devUrl}}
                                [image] ="'https://dev-eofyreport.ezycollect.com.au/assets/images/logo.png'"
                                [autoSetMeta]="false"
                            ></share-buttons>
                        </button> 
                    </div>
                    <hr class="white-line mb-5">
                </div>
            </div>
            <!-- <div class="row no-gutters">
                <div class="col-md-6">
                    <h3 class="chart-header wow animate__animated animate__fadeInUp"> Payment Method </h3>
                    <app-payment-method></app-payment-method>
                </div>
                <div class="col-md-6">
                    <h3 class="chart-header wow animate__animated animate__fadeInUp"> Average Days to Pay After Reminder </h3>
                    <app-pay-after-reminder></app-pay-after-reminder>
                </div>
            </div> -->
        </div>
    </div>
    <div class="gray-bg-image2">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-md-7">
                    <app-payment-data></app-payment-data>
                </div>
                <div class="col-md-5">
                    <div class="data-wrapper">
                        <p class="data-content">
                            If I had to run a company on three measures, those measures would be customer satisfaction, employee satisfaction and cash flow.<br>
                            -Jack Welch
                        </p>
                        <img class="allpayment-img" src="../../../assets/images/allpaymenttypes.svg" />
                    </div>
                </div>
            </div>
            <!-- <div class="row no-gutters">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between align-items-center metrics-header">
                        <div class="d-flex">
                            <img src="../../../assets/images/metrics-icon.svg" class="metrics-icon wow animate__animated animate__rotateIn animate__delay-1s" />
                            <h2 class="section-heading ml-2 wow animate__animated animate__fadeInLeft">Metrics</h2>
                        </div>
                    </div>
                    <hr class="white-line mb-5">
                </div>
            </div> -->
            <!-- <div class="row no-gutters">
                <div class="col-md-6">
                    <h3 class="chart-header wow animate__animated animate__fadeInUp"> Change in Overdue Outstandings </h3>
                    <app-change-in-overdue></app-change-in-overdue>
                </div>
                <div class="col-md-6">
                    <h3 class="chart-header wow animate__animated animate__fadeInUp"> Reminders Sent </h3>
                    <app-reminders-sent-by-day></app-reminders-sent-by-day>
                </div>
            </div> -->
        </div>
    </div>
    <div class="metrics-bgcolor">
        <div class="metrics-bg-image  metrics-mobi-bg-image">
            <div class="container">
                <div class="row no-gutters metrics-columns">
                    <div class="col-md-6">
                        <img src="../../../assets/images/stars.svg" class="star-image wow animate__animated animate__pulse animate__infinite" />
                        <img src="../../../assets/images/mobi-stars.svg" class="mobi-star-image wow animate__animated animate__pulse animate__infinite" />
                        <img src="../../../assets/images/system.svg" class="system-image wow animate__animated animate__fadeIn" />
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex flex-column metrics-content wow animate__animated animate__fadeIn">
                            “In a truly great company profits and cash flow become like blood and water to a healthy body: They are absolutely essential for life but they are not the very point of life”
                            <span>- James C Collins</span>
                        </div>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="col-md-12">
                        <!-- <div class="d-flex justify-content-between customers-header">
                            <h2 class="customer-heading wow animate__animated animate__fadeInLeft">Your Customers</h2> 
                        </div>
                        <hr class="white-line">  -->
                        <!-- <div class="row no-gutters">
                            <div class="col-md-5">
                                <h3 class="top-cust-heading wow animate__animated animate__fadeInUp">Bottom 5 Customers by Days Late</h3>
                            </div>
                            <div class="col-md-7">
                                <app-your-customer-chart1></app-your-customer-chart1>
                            </div>
                        </div>
                        <div class="row no-gutters">
                            <div class="col-md-5">
                                <h3 class="top-cust-heading wow animate__animated animate__fadeInUp">Bottom 5 Customers by Days Late</h3>
                            </div>
                            <div class="col-md-7">
                                <app-your-customer-chart2></app-your-customer-chart2>
                            </div>
                        </div> -->
                        <div class="d-flex align-items-center">
                            <img src="../../../assets/images/customer-invoice.svg" class="customer-invoice wow animate__animated animate__fadeInUp" />
                            <div class="customer-content wow animate__animated animate__fadeInRight">
                                “Never take your eyes off the cash flow because it's the life blood of business.”<br>
                                Richard Branson
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
